import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { parseISO, setHours, setMinutes, addMinutes, formatISO, isBefore } from 'date-fns';
import { getAuth } from 'firebase/auth';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { Button, TextField, Dialog } from '@material-ui/core';
import CoreContainer from '../common/new/container';
import { ArrowForwardIos, Close } from '@material-ui/icons';
import { Divider, MenuItem} from '@mui/material';
import SelectClientDialog from '../selectClientDialog';
import SelectServiceDialog from '../selectServiceDialog';
import { moneyMask } from '../utils';
import bookingTransactionService from '../../services/bookingTransactionService';
import vacanciesService from '../../services/vacanciesService';
import GradientLoading from '../common/GradientLoading/gradientLoading';
import style from '../../styles/global/variables/variables.scss';
import styleNewSchedule from '../../styles/newScheduleDialog/index.scss';

const NewScheduleDialog = (props) => {
	const [isNewSchedule, setIsNewSchedule] = useState(true);
	const initialDate = props.date;
	const location = useLocation();
	const { business } = useSelector((store) => store.business);
	const auth = getAuth();

	const mixpanel = useMixpanel();
	const [client, setClient] = useState('');
	const [date, setDate] = useState(initialDate);
	const [endDate, setEndDate] = useState(initialDate);
	const [time, setTime] = useState('');
	

	const [openSucessModal, setOpenSucessModal] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Tudo certo");
	const [loading, setLoading] = useState(false);

	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [service, setService] = useState('');
	const [professional, setProfessional] = useState('');
	const [blockReason, setBlockReason] = useState('');

	const [openClientDialog, setOpenClientDialog] = useState(false);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);

	const handleError = (error, defaultMessage = 'Ocorreu um erro inesperado. Por favor, tente novamente.') => {
    const knownErrors = {
        'VACANCY_NOT_AVAILABLE': 'O profissional não está disponível no horário selecionado.',
        'SERVICE_DURATION_EXCEEDED': 'A duração do serviço excede o horário disponível.',
        'INVALID_CLIENT': 'Cliente selecionado não é válido.',
    };

    const errorMessage = error?.response?.data?.message || error?.message || defaultMessage;

    if (knownErrors[errorMessage]) {
        return knownErrors[errorMessage];
    }

    console.error('Erro desconhecido:', error);
    return defaultMessage;
};

	const validateReservationDate = () => {
		if (business && auth?.currentUser) {
			setLoading(true);
			let startTimeDate = parseISO(date);
	
			const [hour, minute] = time.split(':').map(Number);
	
			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);
	
			let endTimeDate = addMinutes(startTimeDate, service.duration);
	
			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);
	
			vacanciesService
				.checkVacancies(professional.id, startTimeISO)
				.then((res) => {
					if (res?.data?.message) {
						setMessage(res?.data?.message);
						setOpenMessage(true);
						setLoading(false);
					} else if (res?.status === 200) {
						saveReservation();
					}
				})
				.catch((err) => {
					alert(handleError(err));
					setLoading(false);
				});
		}
	};

	const saveReservation = () => {
		setLoading(true);
		if (business && auth?.currentUser) {
			let startTimeDate = parseISO(date);
			const [hour, minute] = time.split(':').map(Number);
	
			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);
	
			let endTimeDate = addMinutes(startTimeDate, service.duration);
	
			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);
	
			let body = {
				bookings: [
					{
						id: null,
						professional_id: professional.id,
						service_id: service.id,
						start_time: startTimeISO,
						end_time: endTimeISO,
						professional,
						date: startTimeISO,
						time,
						service,
						feature: {},
						booking: {
							id: null,
							professional_id: professional.id,
							service_id: service.id,
							start_time: startTimeDate,
							end_time: endTimeDate,
						},
						temp_id: service.temp_id,
						businessId: business.id,
						clientId: client.id,
					},
				],
				internalNote: '',
				messageClient: '',
			};
	
			bookingTransactionService
				.postBookingTransaction(body)
				.then((res) => {
					mixpanel.track('Nova reserva salva - WB', {
						my_custom_prop: 'foo',
					});
					if (res.status === 200) {
						alert('Cadastro da Reserva Concluído.');
						window.location.reload();
					}
				})
				.catch((err) => {
					alert(handleError(err, 'Erro ao salvar a reserva. Tente novamente.'));
					setLoading(false);
				});
		}
	};

	const saveScheduleBlock = async () => {
		if (business && auth?.currentUser) {
			let startTimeDate = parseISO(date);
			let endTimeDate = parseISO(endDate);
	
			const [startHour, startMinute] = startTime.split(':').map(Number);
			const [endHour, endMinute] = endTime.split(':').map(Number);
	
			startTimeDate = setHours(startTimeDate, startHour);
			startTimeDate = setMinutes(startTimeDate, startMinute);
	
			endTimeDate = setHours(endTimeDate, endHour);
			endTimeDate = setMinutes(endTimeDate, endMinute);
	
			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);
	
			let body = {
				reason: blockReason,
				start_time: startTimeISO,
				end_time: endTimeISO,
				professional_id: professional.id,
			};

			if (isBefore(endTimeDate, startTimeDate)) {
				alert('A data de início deve anteceder a data final do bloqueio da reserva.');
			} else {
				try {
					setLoading(true);
					const response = await bookingTransactionService.postScheduleBreak(body);
					if (response.status === 200) {
						alert('Bloqueio de horário salvo com sucesso');
						window.location.reload();
					}
				} catch (error) {
					setLoading(false);
					const errorMessage = handleError(
						error,
						'Erro ao salvar o bloqueio de horário. Por favor, tente novamente.'
					);
					alert(errorMessage)
				}
			}
		}
	};

	// Lógica para desabilitar o botão enquanto campos não estão preenchidos
	const isSaveDisabled = isNewSchedule
		? (
			!client ||
			!date ||
			!time ||
			!service ||
			!professional
		)
		: (
			!date ||
			!startTime ||
			!endDate ||
			!endTime ||
			!professional
		);

	if (loading) {
		return (
			<div 
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				zIndex: 10000,
			}}
			>
			<Box 
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
			<GradientLoading/>
			</Box>
			</div>
		);
	}

	return (
		<CoreContainer style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
				<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={props.onClose}></Close>
			</div>
			<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
				<Button
					variant="outlined"
					style={{
						backgroundColor: isNewSchedule ? '#3688EB' : '',
						color: isNewSchedule ? '#FFFFFF' : '#3E3E3E',
						borderRadius: '8px',
						width: '50%',
						textTransform: 'none',
					}}
					onClick={() => setIsNewSchedule(true)}>
					Novo agendamento
				</Button>

				<Button
					variant='outlined'
					style={{
						backgroundColor: !isNewSchedule ? '#3688EB' : '',
						color: !isNewSchedule ? '#FFFFFF' : '#3E3E3E',
						borderRadius: '8px',
						width: '50%',
						textTransform: 'none',
					}}
					onClick={() => setIsNewSchedule(false)}>
					Novo bloqueio
				</Button>
			</div>

			{isNewSchedule ? (
				<div className='newScheduleContainer'>

					<div>
						<p>Cliente</p>
						<Button
							className='selectBlock'
							variant='outlined'
							endIcon={<ArrowForwardIos/>}
							onClick={() => setOpenClientDialog(true)}>
							{client ? client.name : 'Selecione um cliente'}
						</Button>
					</div>

					<div>
						<p>Data e Hora</p>
						<div className='inputTimeContainer'>
							<TextField
								className='inputTime'
								value={date}
								variant='outlined'
								onChange={(e) => { setDate(e.target.value); }}
								type='date'
							/>
							<TextField
								className='inputTime'
								value={time}
								variant='outlined'
								onChange={(e) => { setTime(e.target.value); }}
								type='time'
							/>
						</div>
					</div>

					<div>
						<p>Serviço</p>
						<Button
							variant='outlined'
							className='selectBlock'
							endIcon={<ArrowForwardIos/>}
							onClick={() => setOpenServiceDialog(true)}>
							{service ? (
								<div className='serviceText'>
									<p>{service.serviceName}</p>
									<p>{service.duration + 'm'}</p>
									<p>{moneyMask(service?.price, business.currency)}</p>
								</div>
							) : ( 'Selecione um serviço' )}
						</Button>
					</div>

					<div>
						<p>Profissional</p>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem className="serviceText" key={prof.id} value={prof}> <p>{prof.name}</p></MenuItem>
							))}
						</TextField>
					</div>
				</div>
			) : (
				<div className="newScheduleContainer">
					<div>
						<p>Início</p>
						<div className="inputTimeContainer">
							<TextField
								className="inputDateHours"
								value={date}
								variant="outlined"
								onChange={(e) => { setDate(e.target.value) }}
								type="date"
							/>

							<TextField
								className="inputDateHours"
								value={startTime}
								variant="outlined"
								onChange={(e) => { setStartTime(e.target.value) }}
								type="time"
							/>
						</div>
					</div>
					<div>
						<p>Término</p>
						<div className="inputTimeContainer">
							<TextField
								className="inputDateHours"
								value={endDate}
								variant="outlined"
								onChange={(e) => {
									setEndDate(e.target.value);
								}}
								type="date"
							/>
							<TextField
								className="inputDateHours"
								value={endTime}
								variant="outlined"
								onChange={(e) => {
									setEndTime(e.target.value);
								}}
								type="time"
							/>
						</div>
					</div>

					<div>
						<p>Profissional</p>
						<TextField
							select
							variant="outlined"
							value={professional}
							onChange={(e) => setProfessional(e.target.value)}
							fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem key={prof.id} value={prof}>
									{prof.name}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div>
						<p> Qual o motivo do bloqueio? (opcional)</p>
						<TextField
							variant="outlined"
							value={blockReason}
							onChange={(e) => setBlockReason(e.target.value)}
							fullWidth
						/>
					</div>
				</div>
			)}

			<button 
				className="saveButton"
				onClick={() => { isNewSchedule ? validateReservationDate() : saveScheduleBlock() }}
				disabled={isSaveDisabled}
				style={{
					backgroundColor: isSaveDisabled ? "#CCCCCC" : "#3688EB",
					color: "#FFFFFF",
					borderRadius: "8px",
					cursor: isSaveDisabled ? "not-allowed" : "pointer",
				}}
			>
				<p>Salvar</p>
			</button>

			<Dialog
				open={openClientDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectClientDialog onClose={() => setOpenClientDialog(false)} onCloseAll={() => {props.onClose()}} select={setClient} />
			</Dialog>

			<Dialog
				open={openServiceDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectServiceDialog onClose={() => setOpenServiceDialog(false)} select={setService} />
			</Dialog>

			<Dialog
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<p style={{ color: "#3E3E3E", fontSize: "18px", padding: "16px", textAlign: "center" }}>{message}</p>
				<Divider/>
				<div style={{ display: "flex", justifyContent : 'space-around' }}>
					<Button style={{ padding: "16px" }} onClick={() => setOpenMessage(false)}>
						Cancelar
					</Button>
					<Divider flexItem orientation="vertical"></Divider>
					<Button
						style={{ padding: "16px" }}
						onClick={() => { setOpenMessage(false); saveReservation(); }}>
						Continuar mesmo assim? 
					</Button>
				</div>
			</Dialog>
		</CoreContainer>
	);
};

export default NewScheduleDialog;
