import React from "react";
import { Typography } from "@mui/material";

const MenuItem = ({ title, icon, action }) => {
  return (
    <Typography
      style={{
        fontSize: "16px",
        color: "darkgray",
        textAlign: "left",
        marginBottom: "4px",
        paddingLeft: "5% !important",
      }}
      onClick={action}
    >
      {icon}
      {title}
    </Typography>
  )
}

export default MenuItem;