import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from "react-redux";
import { Modal, Alert, Stack } from '@mui/material';
import { getAuth } from "firebase/auth";
import { Box, Button, Dialog, TextField } from '@material-ui/core';
import { MenuItem } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos} from '@material-ui/icons';
import SelectClientDialog from '../../../components/selectClientDialog/index.jsx';
import SelectServiceDialog from '../../../components/selectServiceDialog/index.jsx';
import GradientLoading from '../../common/GradientLoading/gradientLoading.jsx';
import { patchEditSchedule, patchDeleteSchedule } from '../../../services/scheduleService.js';
import { moneyRender } from "../../../components/utils";

import '../../../styles/eventModal/index.scss';
import '../../../styles/schedule/index.scss';

const EditBookingModal = ({isOpen, onClose, onBack, bookingData,  event,  }) => {
	const { business } = useSelector((store) => store.business);
	
	const [booking, setBooking] = useState({});
	const [loading , setLoading] = useState(false);
	const [messageData, setMessageData] = useState({ message: '', isError: false });
	const [openClientDialog, setOpenClientDialog] = useState(false);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);
	const [professional, setProfessional] = useState({});
	const [client, setClient] = useState({});
	const [service, setService] = useState({});
	const [date, setDate] = useState('');
	const [time, setTime] = useState('');
	const [isFormValid, setIsFormValid] = useState(false);
	
	useEffect(() => {
		if (!_.isEmpty(event)) {
			const start = new Date(_.cloneDeep(event.start));
			const formattedDate = start.toISOString().split('T')[0];
			const formattedTime = start.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
			setClient(_.cloneDeep(event.client));
			setService(_.cloneDeep(event.service));
			setBooking(_.cloneDeep(event));
			setDate(formattedDate);
			setTime(formattedTime);
		} else {
			// tratamento go back na tela
		}
	}, []);

	const [auth, setAuth] = useState(getAuth());

	const handleEditBooking = async () => {
		const start_time = new Date(`${date}T${time}`).toISOString();
		console.log(client, service, date, time, professional);
		const updateData = {
			bookings: [
				{
					professional_id: professional.id,
					service_id: service.id,
					start_time,
					end_time: new Date(new Date(start_time).getTime() + service?.duration * 60000).toISOString(),
					id: event.id,
					startTime: start_time,
					firstStartTime: start_time,
					price: service.price,
					duration:service.duration,
				}
			],
		}
		try {
			setLoading(true);
			const response = await patchEditSchedule(event?.bookingTransactionId, updateData);
			window.alert("Agendamento atualizado com sucesso!");
			onClose();
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} catch (error) {
			console.error("Erro ao salvar agendamento:", error);
			window.alert("Erro ao salvar agendamento.");
		}finally{
			setLoading(false);
		}
	};

	const handleDelete = async () => {
		try {
			setLoading(true);
			const response = await patchDeleteSchedule(event?.id);
			window.alert("Agendamento atualizado com sucesso!");
			onClose();
			window.location.reload();
		} catch (error) {
			console.error("Erro ao salvar agendamento:", error);
			window.alert("Erro ao salvar agendamento.");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		validateForm();
	}, [client, service, professional, date, time]);

	const validateForm = () => {
		if (!_.isEmpty(client) && !_.isEmpty(service) && !_.isEmpty(professional) && !_.isEmpty(date) && !_.isEmpty(time)) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	};

	if (loading) {
		return (
		  <div 
		  style={{
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100vw',
			height: '100vh',
			backgroundColor: 'rgba(0, 0, 0, 0.8)',
			zIndex: 500,
		  }}
		  >
		  <Box 
			sx={{
			  position: 'absolute',
			  top: '50%',
			  left: '50%',
			  transform: 'translate(-50%, -50%)',
			}}
		  >
		  <GradientLoading/>
		  </Box>
		  </div>
		);
	}

	if(!_.isEmpty(messageData.message)) {
		return (
			<div style={{
				position: 'fixed',
				top: 0,
				width: '100vw',
				height: '100vh',
				zIndex:999,
				margin: '16px',
			}}>
			{ !messageData.isError && (
    		<Stack sx={{ width: '300px' }}>
        	<Alert severity='success' onClose={() => setMessageData({ message: '', isError: false })}>
            {messageData.message}
        	</Alert>
    		</Stack>
			)}
			{ messageData.isError && (
    		<Stack sx={{ width: '300px' }}>
        	<Alert severity='error' onClose={() => setMessageData({ message: '', isError: false })}>
            {messageData.message}
        	</Alert>
    		</Stack>
			)}
			</div>
		)
	}

  return (
    <Fragment>
      <Modal
        open={isOpen}
        onClose={onClose}
        className='containerModal'
      >
    	<Box className='modal'>
        	<div className='headerModal'>
            	<ArrowBackIos onClick={onBack} style={{cursor:'pointer'}}/>
            	<p style={{cursor: 'pointer'}} className='deleteButton' onClick={() => { handleDelete(); }}>Excluir</p>
          	</div>
          	<div className='formContainer'>
          		<div>
					<p>Cliente</p>
					<Button
						className="selectBlock"
						variant="outlined"
						onClick={() => alert('Você não pode editar o cliente do agendamento.')}>
						{client ? client.name : "Selecione um cliente"}
					</Button>
				</div>
        <div className="inputTimeContainer">
					<TextField
						className="inputTime"
						value={date}
						variant="outlined"
						onChange={(e) => { setDate(e.target.value); }}
						type="date"
					/>
					<TextField
						className="inputTime"
						value={time}
						variant="outlined"
						onChange={(e) => { setTime(e.target.value); }}
						type="time"
					/>
				</div>
				<div>
					<label>Serviço</label>
					<Button
						variant="outlined"
						className="selectBlock"
						endIcon={<ArrowForwardIos/>}
						onClick={() => setOpenServiceDialog(true)}>
						{service ? (
							<div className="serviceText">
								<p>{service?.name ? service?.name : service?.serviceName}</p>
								<p>{service?.duration + "m"}</p>
								<p>{moneyRender(business, service?.price / 100 )}</p>
							</div>
						) : ( "Selecione um serviço" )}
					</Button>
				</div>
        <div>
					<label>Profissional</label>
					<TextField
						select
						variant="outlined"
						value={professional}
						onChange={(e) => setProfessional(e.target.value)}
						placeholder='Selecione um Profissional'
						fullWidth>
							{business?.professionals?.map((prof) => (
								<MenuItem className="serviceText" key={prof.id} value={prof}>
									<p>{prof.name}</p>
								</MenuItem>
							))}
					</TextField>
				</div>
				<div>
					<button className={`saveButton ${!isFormValid ? 'disabled' : ''}`} onClick={ handleEditBooking } disabled={!isFormValid}>
						<p className="editSaveButton">Salvar</p>
					</button>
				</div>
        	</div>
          	<Dialog
				open={openServiceDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectServiceDialog onClose={() => setOpenServiceDialog(false)} select={setService} />
			</Dialog>
        <Dialog>
				<SelectClientDialog  select={setClient} />
			</Dialog>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default EditBookingModal;
