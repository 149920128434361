import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

import {
	Home,
	Clock,
	Services,
	Professionals,
	Clients,
	Signature,
	Question,
	Exit,
	Assistant,
} from "../components/common/icons/Icons";
import { isMobile } from "../utils/isMobile";

const BusinessContext = createContext();

export function BusinessProvider({ children }) {
	const { business } = useSelector((store) => store.business);
	const [showCalendar, setShowCalendar] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [sharedDate, setSharedDate] = useState(new Date());

	const [date, setDate] = useState(new Date());
	const [hours, setHours] = useState([]);
	const [now, setNow] = useState(new Date(Date.now()));

	const [pageType, setPageType] = useState("root");
	const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);
	const [reservationDates, setReservationDates] = useState([]);
	const [scheduleBreakDates, setScheduleBreakDates] = useState([]);
	const [businessData, setBusinessData] = useState(null);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleSetDate = (e) => {
		setDate(e);
		setPageType("root");
	};

	const handleMenu = (_page) => {
		setPageType(_page);
		if (isMobile()) setShowMenu(!showMenu);
	};

	const Menus = [
		{
			title: "Perfil da empresa",
			icon: <Home style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => handleMenu("businessProfile"),
		},
		{
			title: "Horário de funcionamento",
			icon: <Clock style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => handleMenu("openingHours"),
		},
		{
			title: "Serviços",
			icon: <Services style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => handleMenu("services"),
		},
		{
			title: "Profissionais",
			icon: <Professionals style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: null,
		},
		{
			title: "Clientes",
			icon: <Clients style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => handleMenu("listClients"),
		},
		{
			title: "Assinatura",
			icon: <Signature style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: null,
		},
		{
			title: "Perguntas frequentes",
			icon: <Question style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: null,
		},
		{
			title: "Preciso de ajuda",
			icon: <Assistant style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => {
				window.open(`https://wa.me/${process.env.REACT_APP_WHATSAPP_HELP_NUMBER}/?text=Olá!%20Vim%20do%20Prit%20WEB%20gostaria%20de%20ajuda`, "_blank");
			},
		},
		{
			title: "Sair",
			icon: <Exit style={{ marginRight: "10px", width: "16px", height: "16px" }} />,
			action: () => setIsOpenModalLogout(true),
		},
	];

	return (
		<BusinessContext.Provider
			value={{
				business,
				showCalendar,
				setShowCalendar,
				date,
				setDate,
				hours,
				setHours,
				now,
				setNow,
				pageType,
				setPageType,
				handleSetDate,
				Menus,
				isOpenModalLogout,
				setIsOpenModalLogout,
				reservationDates,
				setReservationDates,
				scheduleBreakDates,
				setScheduleBreakDates,
				businessData,
				setBusinessData,
				showMenu,
				toggleMenu,
				sharedDate,
				setSharedDate
			}}>
			{children}
		</BusinessContext.Provider>
	);
}

export function useBusiness() {
	const context = useContext(BusinessContext);
	return context;
}
