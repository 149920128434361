import React, { useEffect, useState } from "react";
import { Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./style.css";
import {
	initialDaysList,
	initialOpeningHoursForm,
	openingHoursSchema,
	timeLapseList,
	availabilityTimeList,
} from "./openingHours-schema";
import businessService from "../../services/businessService";

export default function OpeningHours() {
	const [daysList, setDaysList] = useState([]);
	const [earlyBookings, setEarlyBookings] = useState([]);
	const [loading, setLoading] = useState(false);
	const [business, setBusiness] = useState(null);

	const isEnableSaveButton = !!!business && !!earlyBookings;

	const { register, setValue, getValues } = useForm({
		resolver: yupResolver(openingHoursSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialOpeningHoursForm,
	});

	const validateMinutes = (data) => {
		const newEarlyBooking = [];

		data.map((earlyBooking) => {
			if (earlyBooking.minutes === 0)
				return newEarlyBooking.push({
					value: `${earlyBooking.id}|${earlyBooking.minutes}`,
					label: "Não há mínimo de antecedência",
				});

			if (earlyBooking.minutes > 0 && earlyBooking.minutes < 60)
				return newEarlyBooking.push({
					value: `${earlyBooking.id}|${earlyBooking.minutes}`,
					label: `${earlyBooking.minutes} Minutos`,
				});

			if (earlyBooking.minutes === 60)
				return newEarlyBooking.push({ value: `${earlyBooking.id}|${earlyBooking.minutes}`, label: "1 Hora" });

			return newEarlyBooking.push({
				value: `${earlyBooking.id}|${earlyBooking.minutes}`,
				label: `${earlyBooking.minutes / 60} Horas`,
			});
		});

		setEarlyBookings(newEarlyBooking);
	};

	const getEarlyBooking = async () => {
		const response = await businessService.getEarlyBookings();

		if (response && response.data) {
			validateMinutes(response.data);
		}
	};

	const getBusiness = async () => {
		const response = await businessService.getBusiness();

		if (response && response.data) {
			setOpeningHours(response.data[0]);
		}
	};

	const setOpeningHours = (business) => {
		const timelapse = business.timeLapse || "1";
		const availabilityTime = `${business.availabilityTime}|${business.availabilityTimeRef}`;
		const earlyBooking = business.earlyBooking
			? `${business.earlyBooking.id}|${business.earlyBooking.minutes}`
			: `${earlyBookings[0].value.split("|")[0]}|${earlyBookings[0].value.split("|")[1]}`;

		setBusiness(business);
		business.businessHours.length > 0
			? populateBusinessHours(business.businessHours)
			: setDaysList(JSON.parse(JSON.stringify(initialDaysList)));
		setValue("timelapse", timelapse);
		setValue("availability", availabilityTime);
		setValue("earlyBooking", earlyBooking);
	};

	function formatToTime(isoString) {
		const date = new Date(isoString);
		const hours = date.getUTCHours().toString().padStart(2, "0");
		const minutes = date.getUTCMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	const populateBusinessHours = (businessHours) => {
		const updatedDaysList = JSON.parse(JSON.stringify(initialDaysList));

		businessHours.forEach(({ weekday, openTime, closeTime }) => {
			const day = updatedDaysList.find((day) => day.id === weekday);
			if (day) {
				day.isChecked = true;

				const emptyInterval = day.intervals.find((interval) => interval.start === "" && interval.end === "");
				if (emptyInterval) {
					// Preenche o primeiro intervalo vazio
					emptyInterval.start = formatToTime(openTime);
					emptyInterval.end = formatToTime(closeTime);
				} else {
					// Adiciona um novo intervalo caso todos já estejam preenchidos
					day.intervals.push({
						id: day.intervals.length + 1,
						start: formatToTime(openTime),
						end: formatToTime(closeTime),
					});
				}
			}
		});

		setDaysList(updatedDaysList);
	};

	const loadData = async () => {
		await getEarlyBooking();
	};

	const onSubmit = async (data) => {
		setLoading(true);
		const { timelapse, availability, earlyBooking } = getValues();

		const business_hours = [];

		daysList.forEach((day) => {
			day.intervals.forEach((interval) => {
				if (interval.start.length && interval.end.length && day.isChecked) {
					business_hours.push({
						weekday: day.id,
						openTime: interval.start,
						closeTime: interval.end,
					});
				}
			});
		});

		const body = {
			business_hours,
			time_lapse: Number(timelapse),
			availability_time: Number(availability.split("|")[0]),
			availability_time_ref: availability.split("|")[1],
			early_booking_id: earlyBooking.split("|")[0],
			early_booking: earlyBooking.split("|")[1],
		};

		try {
			const response = await businessService.putBusinessHours(body, business.id);
			if (response.status === 200) {
				window.alert("Horários atualizados com sucesso");
			} else {
				window.alert("Não foi possível atualizar, por favor, tente novamente.");
			}
		} catch (error) {
			console.log(error);
			window.alert("Não foi possível atualizar, por favor, tente novamente.");
		}

		setLoading(false);
	};

	const handleCreateInterval = (dayId) => {
		const newDayList = daysList.map((day) => {
			if (day.id === dayId) {
				const lastIntervalId = day.intervals[day.intervals.length - 1].id;
				return {
					...day,
					intervals: [
						...day.intervals,
						{
							id: lastIntervalId + 1,
							start: "08:00",
							end: "18:00",
						},
					],
				};
			} else {
				return day;
			}
		});

		setDaysList(newDayList);
	};

	const handleRemoveInterval = (dayId, intervalId) => {
		const newDayList = daysList.map((day) => {
			if (day.id === dayId) {
				const newIntervals = [];

				day.intervals.forEach((interval) => {
					if (interval.id !== intervalId) {
						newIntervals.push(interval);
					}
				});

				return {
					...day,
					intervals: newIntervals,
				};
			} else {
				return day;
			}
		});

		setDaysList(newDayList);
	};

	const handleCheckDay = (dayId) => {
		const newDayList = daysList.map((day) => {
			if (day.id === dayId) {
				return {
					...day,
					isChecked: !day.isChecked,
					intervals: [{ id: 1, start: "08:00", end: "18:00" }],
				};
			} else {
				return day;
			}
		});

		setDaysList(newDayList);
	};

	const handleChangeInterval = (event, dayId, intervalId, type) => {
		const newValue = event.target.value;

		const newDayList = daysList.map((day) => {
			if (day.id === dayId) {
				const newIntervals = day.intervals.map((interval) => {
					if (interval.id === intervalId) {
						return {
							...interval,
							[type]: newValue,
						};
					} else {
						return interval;
					}
				});

				return {
					...day,
					intervals: newIntervals,
				};
			} else {
				return day;
			}
		});

		setDaysList(newDayList);
	};

	useEffect(() => {
		loadData();
	}, []);

	useEffect(async () => {
		if (earlyBookings.length) {
			await getBusiness();
		}
	}, [earlyBookings]);

	return (
		<>
			<div className="date-header">
				<Typography className="date-header-title">Horário de Funcionamento</Typography>
			</div>
			<Divider />
			<div className="opening-hours">
				<div className="dynamic-hours">
					{daysList.length ? (
						daysList.map((day) => (
							<div className="dynamic-hours-content" key={day.id}>
								<div index={day.id} className="input-form-dynamic">
									<div className="input-form-dynamic-control">
										<input
											onChange={() => handleCheckDay(day.id)}
											checked={day.isChecked}
											type="checkbox"
											name={`${day.name}_checkbox`}
											className="input-form-checkbox-opening-hours"
										/>
										<Typography className="input-form-label-opening-hours">{day.name}</Typography>
									</div>
									<div className="input-form-dynamic-fields">
										<input
											disabled={!day.isChecked}
											onChange={(e) => handleChangeInterval(e, day.id, 1, "start")}
											type="time"
											className="input-form-text-interval"
											value={day.intervals[0].start || "08:00"}
										/>
										<input
											disabled={!day.isChecked}
											onChange={(e) => handleChangeInterval(e, day.id, 1, "end")}
											type="time"
											className="input-form-text-interval"
											value={day.intervals[0].end || "18:00"}
										/>
									</div>
									<div className="input-form-dynamic-action">
										<button
											disabled={!day.isChecked}
											onClick={() => handleCreateInterval(day.id)}
											className="input-form-dynamic-button">
											+
										</button>
									</div>
								</div>
								{day.intervals
									.filter((interval) => interval.id !== 1)
									.map((interval) => (
										<div index={day.id} className="input-form-dynamic" key={day.id}>
											<div className="input-form-dynamic-control" />
											<div className="input-form-dynamic-fields-secondary">
												<input
													disabled={!day.isChecked}
													onChange={(e) => handleChangeInterval(e, day.id, interval.id, "start")}
													type="time"
													className="input-form-text-interval"
													value={interval.start}
												/>
												<input
													disabled={!day.isChecked}
													onChange={(e) => handleChangeInterval(e, day.id, interval.id, "end")}
													type="time"
													className="input-form-text-interval"
													value={interval.end}
												/>
											</div>
											<div className="input-form-dynamic-action">
												<button
													disabled={!day.isChecked}
													onClick={() => handleRemoveInterval(day.id, interval.id)}
													className="input-form-dynamic-remove-button">
													-
												</button>
											</div>
										</div>
									))}
							</div>
						))
					) : (
						<div className="dynamic-hours-content-placeholder">
							<Typography className="date-header-title">Carregando...</Typography>
						</div>
					)}
					<div className="button-container-opening-hours">
						<button
							disabled={isEnableSaveButton || loading}
							onClick={() => onSubmit()}
							type="button"
							className="button-save">
							Salvar
						</button>
					</div>
				</div>
				<div className="configuration-hours">
					<div className="form-content-opening-hours">
						<div className="input-form-opening-hours">
							<Typography className="input-form-label-opening-hours">
								Mostrar meus horários com intervalos de:
							</Typography>
							<select {...register("timelapse")} className="input-form-text-opening-hours" type="text">
								{timeLapseList.map((x) => (
									<option value={x.value}>{x.label}</option>
								))}
							</select>
						</div>
						<div className="input-form-opening-hours">
							<Typography className="input-form-label-opening-hours">Período de disponibilidade:</Typography>
							<select {...register("availability")} className="input-form-text-opening-hours" type="text">
								{availabilityTimeList.map((x) => (
									<option value={x.value}>{x.label}</option>
								))}
							</select>
						</div>
						<div className="input-form-opening-hours">
							<Typography className="input-form-label-opening-hours">Agendar com antecedência de no mínimo:</Typography>
							<select {...register("earlyBooking")} className="input-form-text-opening-hours" type="text">
								{earlyBookings.length && earlyBookings.map((x) => <option value={x.value}>{x.label}</option>)}
							</select>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
