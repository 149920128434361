import api from "./api";

export const getSector = (businessId) => {
	return api.get("sector/business/" + businessId)
};

export const getSectors = () => {
	return api.get("sector?without_category=true")
};

export const getSectorById = (sectorId) => {
	return api.get("sector/"+sectorId)
};

export default {
	getSector,
	getSectors,
	getSectorById,
};