import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
	return {
		title: {
			color: "#393762",
			fontSize: "1.5rem",
		},
		calendarContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			[theme.breakpoints.up("md")]: {
				flexDirection: "row",
				flexWrap: "wrap",
				justifyContent: "center",
			},
			zIndex: "2",
		},
		calendar: {
			backgroundColor: "#eeeeee",
			marginRight: theme.spacing(0),
			width: "328px",
		},
		reservationList: {
			flex: 1,
			overflow: "auto",
			overflowX: "hidden",
			[theme.breakpoints.up("md")]: {
				width: "50%",
			},
		},
		container: {
			margin: "auto",
			textAlign: "center",
			width: "50%",
			padding: "10px",
		},
		littlePurple: {
			color: "#393762",
			fontSize: "1.25rem",
			lineHeight: "1.2",
			letterSpacing: "-0.5px",
			marginTop: "20px",
		},
		littleBlue: {
			color: "#3689ea",
			fontSize: "1.25rem",
			lineHeight: "1.2",
			letterSpacing: "-0.5px",
			marginTop: "20px",
		},
		field: {
			width: "40px",
			fontSize: "2rem",
			padding: "5px",
		},
		error: {
			color: "#ff3b30",
			fontSize: "0.875rem",
		},
		icon: {
			cursor: "pointer",
			color: "#3689ea",
			transform: "scale(1.5)",
		},
		message: {
			color: "rgba(71, 71, 71, 0.7)",
			fontSize: "0.875rem",
			paddingBottom: "10px",
		},
		menu: {
			"& > p": {
				padding: "8px 4px 8px 5% !important",
				width: "94%",
				display: "flex",
				alignItems: "center",
				"&:hover": {
					borderRadius: "6px",
					backgroundColor: "#39ADC4",
					color: "white !important",
					cursor: "pointer",
					"& > svg": {
						filter: "brightness(0) invert(1) !important",
						fillOpacity: 1,
					},
					"& > svg > rect": {
						filter: "brightness(100%) invert(1) opacity(1) !important",
						fillOpacity: 1,
					},
				},
			},
		},
		linkItem: {
			border: "1px solid darkgray",
			borderRadius: "6px",
			padding: "6px 4px 6px 5% !important",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			width: "82%",
			marginLeft: "5%",
			backgroundColor: "white",
			"@media (max-width: 600px)": {
				width: "86%",
				marginLeft: "5%",
				padding: "6px 4px 6px 8px !important",
			},
		},
		textLinkBusiness: {
			margin: 0,
			fontWeight: 400,
			width: "75%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			fontSize: "1rem",
			lineHeight: "1.5",
			textOverflow: "ellipsis",
		},
	};
});
