const priceTypesReducer = (state = {priceTypeList: []}, action) => {
    switch(action.type) {
        case 'FETCH_PRICE_TYPES_SUCCESS':
            return {
                ...state,
                priceTypeList: action.payload
            };
        default: 
            return state;
    }
}



export default priceTypesReducer;