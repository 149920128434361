import React from "react";
import clsx from "clsx";
import { Typography } from "@mui/material";
import styled from "styled-components";

import imageProfileDefault from "../../../assets/icons/profile.png";
import { telephoneNumberFormat } from "../../utils";

export default function SearchClients({ data, search, selectedClient, setSelectedClient }) {
	if (data.length === 0) {
		return (
			<div className="list-client none-client">
				{search.length === 0 ? "Encontrar seu cliente pelo nome ou número de telefone" : "Nenhum cliente encontrado"}
			</div>
		);
	}

	return data.map((client) => (
		<div key={client.id} className="list-client">
			<button
				onClick={() => setSelectedClient(client)}
				className={clsx("client-btn-details", selectedClient?.id === client.id && "actived")}>
				<StyledLogo className="client-image" background={client.avatarUrl} />
				<div>
					<Typography className="client-name">{client.name}</Typography>
					<Typography className="client-info">{telephoneNumberFormat(client.phone)}</Typography>
				</div>
			</button>
		</div>
	));
}

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "50%")};
	background-position: center;
`;
