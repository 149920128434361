import payout from "./payout";

export const getPaymentList = (bookingTransactionId) => {
	return payout.get("payment/list/grouped/payments/" + bookingTransactionId)
};

export const postCancellationBooking = (data) => {
	return payout.post("payment/cancellation/values/business", data)
};

export const postRefundBusiness = (data) => {
	return payout.post("payment/refund/business", data)
};

export const postPaySubscription = (data) => {
	return payout.post("payment/pay-subscription", data)
};

export const postPaySubscriptionCredit = (data) => {
	return payout.post("payment/recurrence/subscription/prit", data)
};

export const putPaySubscriptionCredit = (data) => {
	return payout.put("payment/recurrence/subscription/prit", data)
};

export const postRecurrenceTransactions = (data) => {
	return payout.post("payment/recurrence/subscription/prit/transactions", data)
};

export const postRecurrenceCancel = (businessId) => {
	return payout.post(`payment/recurrence/subscription/prit/cancel/${businessId}`)
}



export default {
	getPaymentList,
	postCancellationBooking,
	postRefundBusiness,
	postPaySubscription,
	postPaySubscriptionCredit,
	putPaySubscriptionCredit,
	postRecurrenceTransactions,
	postRecurrenceCancel,
};