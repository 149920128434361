import api from "./api";

export const getBusiness = () => {
	return api.get("me/businesses");
};

export const getBusinessPhotos = () => {
	return api.get("me/businesses");
};

export const putBusinessAddress = (id, address) => {
	return api.put(`business/${id}/address`, address);
};

export const postBusiness = (data) => {
	return api.post(`business`, data);
};

export const putBusiness = (id, data) => {
	return api.put(`business/${id}`, data);
};

export const uploadImage = (data) => {
	return api.post("upload", data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const getPhotos = (id) => {
	return api.get(`photo/${id}`);
};

export const deletePhoto = (id) => {
	return api.delete(`photo/${id}`);
};

export const sendPhotos = (data) => {
	return api.post("photo", data);
};

export const updatePhotos = (data, business_id) => {
	return api.patch(`photo/business/${business_id}`, data);
};

export const getEarlyBookings = () => {
	return api.get(`early_booking`);
}

export const putBusinessHours = (data, id) => {
	return api.put(`/business/${id}/business_hours_time`, data)
}

export default {
	getBusiness,
	getBusinessPhotos,
	putBusinessAddress,
	postBusiness,
	putBusiness,
	getPhotos,
	uploadImage,
	updatePhotos,
	deletePhoto,
	sendPhotos,
	getEarlyBookings,
	putBusinessHours,
};
