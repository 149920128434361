import React from "react";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import {
	Divider,
	Typography
} from "@mui/material";

const Header = (props) => {
	const { title, onBack  } = props;

	return (
		<>
			<div styles={{ backgroundColor: "#eeeeee" }}>
				<div
					style={{
						display: "flex",
						width: "100%",
						backgroundColor: "#eeeeee",
						position: "sticky",
						top: "0",
						zIndex: "1",
						boxShadow: "0px 0px 5px #888888",
						alignItems: "center",
					}}>
					<div style={{ cursor: "pointer", width: "4vw" }} onClick={() => onBack()}>
						<ArrowBackIosRounded
							style={{
								color: "gray",
								width: "calc(0.5vw + 20px)",
								height: "calc(0.5vw + 20px)",
								margin: "0.5vw",
								cursor: "pointer",
							}}></ArrowBackIosRounded>
					</div>
					<Typography
						style={{
							color: "lightgray",
							margin: "1vw",
							width: "100%",
							textAlign: "center",
							fontSize: "18px",
							fontWeight: "bold",
							position: "relative",
							right: "1.5vw",
						}}>
						{title}
					</Typography>
				</div>

				<Divider></Divider>
			</div>
		</>
	);
};
export default Header;
