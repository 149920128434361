import React from 'react';

const CoreProgressBar = ({ progress }) => {
  const containerStyle = {
    width: '100%',
    maxWidth: '230px',
    height: '12px',
    backgroundColor: '#e0e0de',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const fillerStyle = {
    height: '100%',
    width: `${progress}%`,
    background: 'linear-gradient(90deg, #39ADC4 0%, #B545E9 100%)',
    transition: 'width 0.3s ease-in-out', 
  };

  return (
    <div style={containerStyle}>
      <div style={fillerStyle}></div>
    </div>
  );
};

export default CoreProgressBar;
