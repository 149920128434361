import { combineReducers } from "redux";

import business from "../../store/reducers/businessReducer";
import notifications from "../../store/reducers/notificationsReducer";
import schedule from "../../store/reducers/scheduleReducer";
import paymentList from "../../store/reducers/paymentListReducer";
import bookingTransaction from "../../store/reducers/bookingTransactionReducer";
import clients from "../../store/reducers/clientReducer";
import sector from "../../store/reducers/sectorReducer";
import professionalServices from "../../store/reducers/professionalServiceReducer";
import me from "./meReducer";
import subscription_data from "./subscriptionDataReducer";
import subscription_current from "./subscriptionCurrentReducer";
import services from "../redux/modules/services";
import itemsServices from "./businessServicesSelectReducer";
import priceTypes from "./priceTypesReducer";

const appReducer = combineReducers({
	business,
	notifications,
	schedule,
	paymentList,
	bookingTransaction,
	clients,
	sector,
	professionalServices,
	me,
	subscription_data,
	subscription_current,
	services,
	itemsServices,
	priceTypes
});

const reducers = (state, action) => {
	return appReducer(state, action);
};

export default reducers;