import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Container, CircularProgress } from "@material-ui/core";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import meAction from "../../store/actions/meAction";
import subscriptionDataAction from "../../store/actions/subscriptionDataAction";
import businessAction from "../../store/actions/businessAction";
import Modal from "@mui/material/Modal";
import { PrivacyPolicy } from "../../components/utils/index";
import CoreContainer from "../common/new/container";
import mostRequestedBanner from "../../assets/images/plansScreen/most-requested-banner.svg";
import arrow from "../../assets/images/plansScreen/VectorArrow.svg";
import checked from "../../assets/images/plansScreen/VectorChecked.svg";
import businessService from "../../services/businessService";
import subscriptionService from "../../services/subscriptionDataService";
import "../../styles/payment/payment.scss";

const Payment = () => {
	const [selectedPlan, setSelectedPlan] = useState("Mensal");
	const [expanded, setExpanded] = useState(false);

	const subscriptionData = useSelector((state) => state.subscription_data.subscription_data);
	const subscriptionState = useSelector((state) => state.subscription_current);

	const business = useSelector((state) => state.business);
	const location = useLocation();

	const validationPage = location?.state?.view;
	const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;
	const expiration = location?.state?.expiration;

	const pathParams = window.location.pathname.split("/");
	const advancedPlanId = process.env.REACT_APP_ADVANCED_PLAN_ID;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { token } = useParams();

	const [auth, setAuth] = useState(getAuth());
	const [open, setOpen] = useState(false);

	const _date = business.businessInfo?.createdAt ?? moment().format("YYYY-MM-DD");
	const formattedDate = new Date(_date).toISOString();
	const businessId = business.businessInfo ? business.businessInfo.id : 0;

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const validateBusinessId = async (id) => {
		if (!id) {
			const data = await businessService.getBusiness();

			if (!data) {
				return 0;
			}

			return data.data[0].id;
		}

		return id;
	};

	const levels = !expiration || subscriptionState.subscription_current?.expiration === null;

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (!pathParams || pathParams.length < 3) {
				if (user) {
					let tokenAuth = user.accessToken;
					let config = {
						headers: {
							Authorization: "Bearer " + tokenAuth,
						},
					};

					dispatch(meAction.getMe(config));
					dispatch(businessAction.getBusiness(config));
				} else {
					setAuth(getAuth());
					navigate("/welcome");
				}
			} else if (pathParams && pathParams.length > 3) {
				let tokenAuth = pathParams[3];
				let config = {
					headers: {
						Authorization: "Bearer " + tokenAuth,
					},
				};
				localStorage.setItem("accessToken", tokenAuth);
				dispatch(meAction.getMe());
				dispatch(businessAction.getBusiness(config));
			}
		});

		return () => unsubscribe();
	}, [auth, token]);

	useEffect(() => {
		if (
			!validationPage &&
			subscriptionState.subscription_current?.status === "active" &&
			!subscriptionState.subscription_current.is_recurrence
		) {
			navigate("/menu/plan");
		} else if (!validationPage && subscriptionState.subscription_current?.status === "active") {
			navigate("/menu/plan", { state: { card: subscriptionState.subscription_current } });
		}
	}, []);

	useEffect(() => {
		const fetchSubscriptionData = async () => {
			if (businessId === 0) {
				try {
					if (!auth) {
						const authInstance = getAuth();
						setAuth(authInstance);
					}

					if (auth?.currentUser || pathParams) {
						const id = await validateBusinessId(businessId);
						
						dispatch(subscriptionDataAction.getSubscriptionData(formattedDate, id, levels));
						dispatch(subscriptionDataAction.getSubscriptionCurrent(id));
					}
				} catch (error) {
					console.error("Erro ao buscar os dados da assinatura:", error);
				}
			} else if ((auth?.currentUser || pathParams) && businessId) {
				await dispatch(subscriptionDataAction.getSubscriptionData(formattedDate, businessId, levels));
				await dispatch(subscriptionDataAction.getSubscriptionCurrent(businessId));
			}
		};
		fetchSubscriptionData();
	}, [business]);

	const handlePeriodClick = (plan) => {
		setSelectedPlan(plan === selectedPlan ? null : plan);
	};

	const handleClick = (selectedPlan) => {
		if (!business?.businessInfo?.address?.postalCode) {
			navigate("/address", { state: { id: selectedPlan?.id, isCardAndPlanUpdate: isCardAndPlanUpdate } });
		} else {
			navigate(`/menu/method/credit`, {
				state: { id: selectedPlan?.id, isCardAndPlanUpdate: isCardAndPlanUpdate },
			});
		}
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const sortedPlans = subscriptionData?.sort((a, b) => a.mountPrice - b.mountPrice);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		height: "80%",
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		pt: 2,
		px: 4,
		pb: 3,
		overflow: "auto",
		"&:focus": {
			outline: "none",
		},
	};

	if (!subscriptionState.subscription_current) {
		return (
			<Container
				maxWidth="sm"
				style={{
					background: "#fff",
					borderRadius: 3,
					border: "none",
					color: "white",
					minWidth: "100vw",
					minHeight: "100vh",
					padding: "0 30px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<CircularProgress />
			</Container>
		);
	}

	const subtitles = {
		iniciante: "Descubra o básico com nosso plano Iniciante.",
		básico: "Mais recursos, mais possibilidades.",
		avançado: "Máxima eficiência com recursos avançados.",
		profissional: "O pacote completo para profissionais.",
	};

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<h1 className="TitlePlans">Selecione seu Plano</h1>
	
			<section className="periodBox">
				<button
					className={selectedPlan === "Mensal" ? "buttonActivated" : "buttonDefault"}
					onClick={() => handlePeriodClick("Mensal")}>
					Mensal
				</button>
				<button
					className={selectedPlan === "Anual" ? "buttonActivated" : "buttonDefault"}
					onClick={() => handlePeriodClick("Anual")}>
					Anual
				</button>
			</section>
	
			<div className="planContainer">
				{sortedPlans
					.filter((plan) => {
						return selectedPlan === "Mensal" ? plan.daysRef === 30 : plan.daysRef === 365;
					})
					.map((plan) => (
						<div key={plan.id} className={plan.id === advancedPlanId ? "borderAdvanced" : ""}>
							<div
								className="planBox"
								style={{ marginRight: window.innerWidth > window.innerHeight ? "8px" : "0" }}>
								{plan.id === advancedPlanId && selectedPlan === "Mensal" && (
									<div className="advancedPlanBox">
										<img src={mostRequestedBanner} alt="banner-img" />
									</div>
								)}
								<h3 className="planTitle">{capitalizeFirstLetter(plan.title)}</h3>
								<p className="planSubs">{subtitles[plan.title]}</p>
								<Accordion
									expanded={expanded === plan.title}
									onChange={(event, isExpanded) => {
										event.stopPropagation();
										setExpanded(isExpanded ? plan.title : "");
									}}>
									<AccordionSummary
										className="accordionContainer"
										expandIcon={<img src={arrow} alt="arrowImg" />}
										aria-controls="panel-content"
										sx={{
											padding: 0,
											width: "100%",
										}}
										id="panel-header">
										<div className="detailsContainer">
											<p className="planPrice">
												{(plan.mountPrice / 100).toLocaleString("pt-BR", {
													style: "currency",
													currency: "BRL",
												})}
												<span>{selectedPlan === "Mensal" ? "/mês" : "/ano"}</span>
											</p>
											<p className="planDetails">Detalhes</p>
										</div>
									</AccordionSummary>
									<AccordionDetails
										style={{ minWidth: "-webkit-fill-available", backgroundColor: "white" }}>
										<p className="planDescription">
											{plan.description.split("\n").map((line, index) => (
												<React.Fragment key={index}>
													<img src={checked} alt="checkedIcon" /> {line}
													<br />
												</React.Fragment>
											))}
										</p>
									</AccordionDetails>
								</Accordion>
								<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
									<Button
										variant="contained"
										color="primary"
										fullWidth
										style={{
											backgroundColor: "#3688EB",
											borderRadius: "8px",
											fontWeight: "bold",
											textTransform: "none",
											width: "70%",
										}}
										onClick={() => handleClick(plan)}>
										Contratar Plano
									</Button>
								</div>
							</div>
						</div>
					))}
			</div>
	
			<p className="privacyPolicy">
				Ao clicar em “Contratar Plano” você concorda com nossa{" "}
				<span style={{ color: "#3688EB", cursor: "pointer" }} onClick={handleOpen}>
					Política de Privacidade
				</span>
				.
			</p>
	
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description">
				<Box sx={{ ...style, width: 400, whiteSpace: "pre-line" }}>
					<h2 id="parent-modal-title">Política de Privacidade</h2>
					<p id="parent-modal-description">{PrivacyPolicy}</p>
				</Box>
			</Modal>
		</CoreContainer>
	);
	
};

export default Payment;
