import { Container } from "@mui/material";
import React from "react";

const CoreContainer = ({ children, style, transparent, noHeight }) => {
	return (
		<div
			style={{
				width: "100dvw",
				height: "100dvh",
				overflowX: "hidden",
				overflowY: "hidden",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: transparent ? "transparent" : "#FAFAFA",
			}}>
			<Container
				style={{
					...style,
					background: "#ffffff",
					borderRadius: window.innerWidth > window.innerHeight?"12px":"0",
					color: "white",
					width: window.innerWidth > window.innerHeight ? "450px" : "100dvw",
					height: !noHeight ? window.innerWidth > window.innerHeight ? "600px" : "100dvh" : "",
					overflowX: "hidden",
					overflowY: "hidden",
					boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
					display: "flex",
					justifyContent: "start",
					alignItems: "start",
					flexDirection: "column",
					padding: "24px",
				}}>
				{children}
			</Container>
		</div>
	);
};

export default CoreContainer;
