import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import CoreProgressBar from "./progressBar";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";

const CoreAppBar = ({ progress, text }) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				gap: "16px",
				alignItems: "center",
				justifyContent: "space-between",
				width: "100%",
				marginTop: "16px",
			}}
			onClick={() => navigate(-1)}>
			<ArrowBackIos style={{ width: "30px", color: " #616161", cursor: 'pointer' }} />
			{progress && <CoreProgressBar progress={progress} />}
			{text && <Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>{text}</Typography>}
			<div style={{ width: "30px" }}></div>
		</div>
	);
};

export default CoreAppBar;
