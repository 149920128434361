import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { isMobile } from "../../../utils/isMobile";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import "../../../styles/toolBar/index.scss";

const CustomToolbar = ({ date, onNavigate, onViewChange, currentView }) => {
	const formattedLabel = moment(date).format("dddd, D [de] MMMM");

	const handleNavigate = (direction) => {
		const newDate = moment(date).add(direction, "days").toDate();
		onNavigate(newDate); // Chama a função passada como prop para atualizar a data
	};

	return (
		<div className="toolBar">
			<div className="navigators">
				<button onClick={() => handleNavigate(-1)}>
					<ArrowBackIos style={{fontSize: '1.3rem'}}/>
				</button>
				<button onClick={() => handleNavigate(1)} className="arrowRight">
					<ArrowForwardIos style={{fontSize: '1.3rem'}}/>
				</button>
			</div>

			<div
				className="dateCalendar"
				style={{ textAlign: "center", margin: "10px 0", display: isMobile() ? "none" : "flex" }}>
				<span style={{ fontSize: "16px", fontWeight: "500" }}>
					{formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1)}
				</span>
			</div>

			<div className="view-buttons">
				<button
					className={currentView === "agenda" ? "active" : ""}
					onClick={() => onViewChange("agenda")}>
					Agenda
				</button>
				<button
					className={currentView === "day" ? "active" : ""}
					onClick={() => onViewChange("day")}>
					Dia
				</button>
			</div>
		</div>
	);
};

export default CustomToolbar;
