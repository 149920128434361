import { ReactComponent as LogoSvg } from "../../../assets/icons/logo.svg";
import { ReactComponent as LogoWhiteSvg } from "../../../assets/icons/logoWhite.svg";
import { ReactComponent as LinkSvg } from "../../../assets/icons/link.svg";
import { ReactComponent as ConfigSvg } from "../../../assets/icons/config.svg";
import { ReactComponent as SignatureSvg } from "../../../assets/icons/signature.svg";
import { ReactComponent as QuestionSvg } from "../../../assets/icons/question.svg";
import { ReactComponent as ExitSvg } from "../../../assets/icons/exit.svg";
import { ReactComponent as ClientsSvg } from "../../../assets/icons/clients.svg";
import { ReactComponent as OpenSvg } from "../../../assets/icons/open.svg";
import { ReactComponent as CopySvg } from "../../../assets/icons/copy.svg";
import { ReactComponent as ClockSvg } from "../../../assets/icons/clock.svg";
import { ReactComponent as ProfessionalsSvg } from "../../../assets/icons/professionals.svg";
import { ReactComponent as ServicesSvg } from "../../../assets/icons/services.svg";
import { ReactComponent as HomeSvg } from "../../../assets/icons/home.svg";
import { ReactComponent as EditSvg } from "../../../assets/icons/edit.svg";
import { ReactComponent as PhotoSvg } from "../../../assets/icons/photo.svg";
import { ReactComponent as ProfileSvg } from "../../../assets/icons/profile.svg";
import { ReactComponent as TrashSvg } from "../../../assets/icons/trash-solid.svg";
import { ReactComponent as BarsSvg } from "../../../assets/icons/bars-solid.svg";
import { ReactComponent as AssistantSvg } from "../../../assets/icons/assistant.svg";

export const Logo = LogoSvg;
export const LogoWhite = LogoWhiteSvg;
export const Link = LinkSvg;
export const Config = ConfigSvg;
export const Signature = SignatureSvg;
export const Question = QuestionSvg;
export const Exit = ExitSvg;
export const Clients = ClientsSvg;
export const Open = OpenSvg;
export const Copy = CopySvg;
export const Clock = ClockSvg;
export const Professionals = ProfessionalsSvg;
export const Services = ServicesSvg;
export const Home = HomeSvg;
export const Edit = EditSvg;
export const Photo = PhotoSvg;
export const Profile = ProfileSvg;
export const Trash = TrashSvg;
export const Bars = BarsSvg;
export const Assistant = AssistantSvg;
