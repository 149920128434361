import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
	Backdrop,
	Button,
	CircularProgress,
	Dialog,
	Divider,
	InputAdornment,
	OutlinedInput,
	TextField,
} from "@mui/material";
import useStyles from "./styles";
import { getAuth, updateProfile, updateEmail } from "firebase/auth";
import { Close, DeleteForever, Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Logo } from "../common/icons/Icons";
import NewClientDialog from "../newClientDialog";
import { Typography } from "@material-ui/core";
import Calendar from "../common/Calendar";
import TimeKeeper from "react-timekeeper";
import { convertDayOfWeek } from "utils";
import axios from "axios";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import ClockPicker from "../common/ClockPicker";
import MensageDialog from "../mensageDialog";
import { moneyMask } from "../utils";
import OkModal from "../common/okModal";
import Header from "../common/Header";
import { useSelector, useDispatch } from "react-redux";
import businessAction from "../../store/actions/businessAction";
import clientAction from "../../store/actions/clientAction";
import sectorAction from "../../store/actions/sectorAction";
import professionalServiceAction from "../../store/actions/professionalServiceAction";
import bookingTransactionService from "../../services/bookingTransactionService";
import vacanciesService from "../../services/vacanciesService";
import { useMixpanel } from "react-mixpanel-browser";
import scheduleService from "../../services/scheduleService";

const NewReservation = (props) => {
	const [auth, setAuth] = useState(getAuth());

	const dispatch = useDispatch();
	const classes = useStyles();
	const navigate = useNavigate();
	const mixpanel = useMixpanel();
	const location = useLocation();

	const { updateBookingId } = location?.state;

	const myProfessionalId = location?.state?.myProfessionalId;

	const [update, setUpdate] = useState(false);

	const [openSucessModal, setOpenSucessModal] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Tudo certo");

	const [openInfoModal, setOpenInfoModal] = useState(false);
	const [info, setInfo] = useState("");

	const [availableProfessionals, setAvailableProfessionals] = useState([]);

	const { sector } = useSelector((store) => store.sector);
	const { professionalServices } = useSelector((store) => store.professionalServices);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedServices, setSelectedServices] = useState([]);

	const [openClientModal, setOpenClientModal] = useState(false);
	const [openDateModal, setOpenDateModal] = useState(false);
	const [openTimeModal, setOpenTimeModal] = useState(false);

	const { clients } = useSelector((store) => store.clients);
	const [selectedClient, setSelectedClient] = useState(null);
	const now = Date.now();

	const [showProfessionals, setShowProfessionals] = useState(false);

	const [showServices, setShowServices] = useState(false);

	const [newSchedules, setNewSchedules] = useState([{ service: null, professional: null, date: null, time: null }]);

	const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(0);

	const { business } = useSelector((store) => store.business);

	const [loading, setLoading] = useState(true);

	const [searchClients, setSearchClients] = useState("");
	const [searchServices, setSearchServices] = useState("");
	const [searchProfessionals, setSearchProfessionals] = useState("");

	const updateNewSchedules = (aux) => {
		setNewSchedules(aux);
		setUpdate(!update);
	};

	useEffect(() => {
		const request = setInterval(() => {
			if (auth?.currentUser) {
				dispatch(businessAction.getBusiness());
				clearInterval(request);
			} else {
				setAuth(getAuth());
			}
		}, 1000);
	}, []);

	useEffect(() => {
		setAvailableProfessionals(business?.professionals);
	}, [business]);

	useEffect(() => {
		if (sector) {
			setCategories(sector[0]?.categories);
			setLoading(false);
		}
	}, [sector]);

	useEffect(() => {
		if (business && !openClientModal) {
			dispatch(clientAction.getClients(business?.id, myProfessionalId));
			dispatch(sectorAction.getSector(business?.id));
		}
	}, [business, openClientModal]);

	useEffect(() => {
		if (professionalServices) {
			setSelectedServices(professionalServices);
			setLoading(false);
		}
	}, [professionalServices]);

	const getServices = (index, categoryId) => {
		if (business && !openClientModal) {
			dispatch(professionalServiceAction.getProfessionalServices(newSchedules[index].professional?.id, categoryId));
		}
	};

	const saveReservation = () => {
		if (business) {
			if (auth?.currentUser) {
				let body = {
					bookings: newSchedules.map(({ service, professional, date, time }) => {
						let startTimeDate = new Date(date),
						endTimeDate = new Date(date);

						startTimeDate.setHours(time.hour);
						endTimeDate.setHours(
							time.minute + service.duration >= 60 ? (time.hour == 23 ? 0 : time.hour + 1) : time.hour
						);

						startTimeDate.setMinutes(time.minute);
						endTimeDate.setMinutes((time.minute + service.duration) % 60);

						return {
							id: null,
							professional_id: professional.id,
							service_id: service.id,
							start_time: startTimeDate.toISOString(),
							end_time: endTimeDate.toISOString(),
							professional,
							date: new Date(date).toISOString(),
							time,
							service,
							feature: {},
							booking: {
								id: null,
								professional_id: professional.id,
								service_id: service.id,
								start_time: startTimeDate,
								end_time: endTimeDate,
							},
							temp_id: service.temp_id,
							businessId: business.id,
							clientId: selectedClient.id,
						};
					}),
					internalNote: "",
					messageClient: "",
				};
				if (updateBookingId) {
					scheduleService.patchEditSchedule(updateBookingId,body).then((res) => {
						setOpenSucessModal(true);
						mixpanel.track("Reserva editada - WB", {
							my_custom_prop: "foo",
						});
					})
					.catch((err) => {
						alert("Você não tem permissão para editar esse agendamento")
					});
				} else {
					bookingTransactionService
						.postBookingTransaction(body)
						.then((res) => {
							setOpenSucessModal(true);
							mixpanel.track("Nova reserva salva - WB", {
								my_custom_prop: "foo",
							});
						})
						.catch((err) => {
							console.error(err)
						});
				}
			}
		}
	};

	const validateReservationDate = () => {
		if (business && !openClientModal) {
			if (auth?.currentUser) {
				let schedule = newSchedules[selectedScheduleIndex];
				let dateTime = schedule.date;
				dateTime.setHours(schedule.time.hour);
				dateTime.setMinutes(schedule.time.minute);

				vacanciesService
					.checkVacancies(newSchedules[selectedScheduleIndex].professional.id, dateTime.toISOString())
					.then((res) => {
						setLoading(false);
						if (res?.data?.message) {
							setMessage(res?.data?.message);
							setOpenMessage(true);
						} else {
							setShowProfessionals(false);
							setOpenDateModal(false);
							setOpenTimeModal(false);
						}
						setLoading(false);
					})
					.catch((err) => {
						//console.log(err);
					});
			}
		}
	};

	return (
		<>
			<SpinnerLoading loading={loading}></SpinnerLoading>

			<OkModal
				open={openSucessModal}
				setOpen={setOpenSucessModal}
				onConfirm={() => navigate("/schedule")}
				message={"Reserva "+(updateBookingId?"editada":"realizada")+" com sucesso!"}></OkModal>
			<OkModal open={openInfoModal} setOpen={setOpenInfoModal} message={info}></OkModal>

			<Dialog
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ margin: "30px", textAlign: "center" }}>
					<strong className={classes.littlePurple}>{message}</strong>
				</Typography>
				<Divider></Divider>
				<div style={{ display: "flex", justifyContent: "space-around" }}>
					<Button style={{ padding: "15px" }} onClick={() => setOpenMessage(false)}>
						Cancelar
					</Button>
					<Divider flexItem orientation="vertical"></Divider>
					<Button
						style={{ padding: "15px" }}
						onClick={() => {
							setOpenMessage(false);
							setShowProfessionals(false);
							setOpenDateModal(false);
							setOpenTimeModal(false);
						}}>
						Continuar mesmo assim
					</Button>
				</div>
			</Dialog>

			<NewClientDialog
				open={openClientModal}
				setOpen={setOpenClientModal}
				businessId={business?.id}
				myProfessionalId={myProfessionalId}>
			</NewClientDialog>

			<Dialog open={showServices} PaperProps={{ style: { maxWidth: "600px" } }}>
				<div style={{ display: "flex", backgroundColor: "#eeeeee", boxShadow: "0px 0px 5px #888888" }}>
					<Typography
						style={{
							margin: "30px",
							color: "gray",
							width: "100%",
							fontSize: "28px",
							fontWeight: "bold",
						}}>
						Serviços
					</Typography>
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							setShowServices(false);
						}}>
						<Close style={{ color: "gray", transform: "scale(1.5)", margin: "40px 30px", cursor: "pointer" }}></Close>
					</div>
				</div>

				<div style={{ display: "flex", margin: "20px" }}>
					{newSchedules[selectedScheduleIndex].professional?.categories &&
						newSchedules[selectedScheduleIndex].professional?.categories.map((categoryId) => {
							for (let i = 0; i < categories.length; i++) {
								if (categoryId == categories[i].id) {
									return (
										<Button
											onClick={() => {
												setLoading(true);

												getServices(selectedScheduleIndex, categoryId);
												setSelectedCategory(categories[i]);
											}}
											style={{
												backgroundColor: selectedCategory?.id == categoryId ? "#3689ea" : "#eeeeee",
												color: selectedCategory?.id == categoryId ? "white" : "gray",
												borderRadius: 30,
												padding: "10px 10px",
												margin: "5px",
												boxShadow: "0px 0px 5px #888888",
											}}>
											{categories[i].name}
										</Button>
									);
								}
							}
						})}
				</div>

				<Divider></Divider>

				<TextField
					onChange={(e) => {
						setSearchServices(e.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								<Search style={{ cursor: "pointer" }} />
							</InputAdornment>
						),
					}}
					placeholder={"Procurar serviços..."}
					className={classes.roundedTextField}
					style={{
						width: "560px",
						padding: "20px",
					}}></TextField>

				{!selectedCategory || !selectedServices ? (
					<div style={{ height: "600px", alignItems: "center" }}>
						<Typography style={{ textAlign: "center", color: "gray", marginTop: "50px", fontSize: "20px" }}>
							Selecione uma das categorias acima...
						</Typography>
					</div>
				) : (
					<div style={{ padding: "0px" }}>
						<SpinnerLoading loading={loading}></SpinnerLoading>
						{selectedServices &&
							selectedServices.map(({ service }) => {
								if (
									searchServices.trim() === "" ||
									service.name.toLowerCase().includes(searchServices.trim().toLowerCase())
								) {
									return (
										<>
											<div
												style={{ display: "flex", cursor: "pointer", padding: "10px" }}
												onClick={() => {
													let aux = newSchedules;
													aux[selectedScheduleIndex].service = service;
													updateNewSchedules(aux);
													setShowServices(false);
												}}>
												<div
													style={{
														display: "flex",
														justifyContent: "space-around",
														flexDirection: "row",
														
													}}>
													<Typography style={{ color: "gray", fontSize: "22px",width: "450px", marginLeft:"15px"}}>{service?.name}</Typography>
													<Typography style={{ color: "#3689ea", fontSize: "22px" }}>
														{moneyMask(service?.price / 100, business.currency)}
													</Typography>
												</div>
											</div>
											<Divider></Divider>
										</>
									);
								}
							})}
						<div style={{ display: "flex", justifyContent: "center" }}>
							{
								<>
									<Typography
										style={{
											fontSize: "15px",

											textAlign: "center",
											margin: "10px",
											color: "gray",
										}}>
										{availableProfessionals?.length > 0
											? "Não encontrou o serviço que você estava procurando? Talvez o profissional selecionado não o realize, tente "
											: "Não foram encotrados profissionais disponíveis"}
										<Button
											onClick={() => {
												setShowServices(false);
												setShowProfessionals(true);
											}}>
											{"escolher outro profissional"}
										</Button>
									</Typography>
								</>
							}
						</div>
					</div>
				)}
			</Dialog>

			<Dialog open={showProfessionals}>
				<div style={{ display: "flex", width: "100%", backgroundColor: "#eeeeee" }}>
					<Typography
						style={{
							margin: "30px",
							color: "gray",
							width: "100%",
							fontSize: "28px",
							fontWeight: "bold",
						}}>
						Profissionais
					</Typography>
					<div style={{ cursor: "pointer", width: "108px" }} onClick={() => setShowProfessionals(false)}>
						<Close style={{ color: "gray", transform: "scale(1.5)", margin: "40px", cursor: "pointer" }}></Close>
					</div>
				</div>

				<TextField
					onChange={(e) => {
						setSearchProfessionals(e.target.value);
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								<Search style={{ cursor: "pointer" }} />
							</InputAdornment>
						),
					}}
					placeholder={"Procurar profissionais..."}
					className={classes.roundedTextField}
					style={{
						width: "550px",
						padding: "20px",
					}}></TextField>

				<div style={{}}>
					{availableProfessionals &&
						availableProfessionals.map((professional) => {
							if (
								searchProfessionals.trim() === "" ||
								professional.name.toLowerCase().includes(searchProfessionals.trim().toLowerCase())
							) {
								return (
									<>
										<div
											style={{ display: "flex", cursor: "pointer", padding: "20px 30px 0 30px " }}
											onClick={() => {
												let aux = newSchedules;
												aux[selectedScheduleIndex].professional = professional;
												if (aux[selectedScheduleIndex].date && aux[selectedScheduleIndex].time) {
													validateReservationDate();
												} else {
													setShowProfessionals(false);
												}
											}}>
											<AccountCircle
												style={{
													color: "lightgray",
													borderRadius: 30,
													width: "70px",
													height: "70px",
												}}></AccountCircle>
											<div style={{ marginLeft: "10px", marginTop: "15px" }}>
												<Typography style={{ color: "gray", fontSize: "15px" }}>{professional?.name}</Typography>
												<Typography style={{ color: "gray", fontSize: "15px" }}>{professional?.phone}</Typography>
											</div>
										</div>

										<Divider style={{ marginTop: "30px" }}></Divider>
									</>
								);
							}
						})}

					<div style={{ display: "flex", justifyContent: "center" }}>
						{
							<>
								<Typography
									style={{
										fontSize: "15px",

										textAlign: "center",
										margin: "10px",
										color: "gray",
									}}>
									{availableProfessionals?.length > 0
										? "Não encontrou o profissional que você estava procurando? Talvez ele ainda não realize serviços"
										: "Não foram encotrados profissionais disponíveis"}
								</Typography>
							</>
						}
					</div>
				</div>
			</Dialog>

			<Dialog PaperProps={{ style: { width: "500px", height: "600px", padding: "15px 30px", borderRadius:"50px" } }} open={openDateModal}>
				<Calendar
					backgroundColor="white"
					setDate={(date) => {
						var aux = newSchedules;
						aux[selectedScheduleIndex].date = date;
						if (aux[selectedScheduleIndex].professional && aux[selectedScheduleIndex].time) {
							validateReservationDate();
						} else {
							setOpenDateModal(false);
						}
					}}
					date={newSchedules[selectedScheduleIndex]?.date || new Date(Date.now())}
					style={{
						margin: "-200px -300px",
						transform: "scale(0.5)",
					}}></Calendar>
			</Dialog>

			<Dialog open={openTimeModal} PaperProps={{ style: { borderRadius:"50px" } }}>
				<SpinnerLoading loading={loading}></SpinnerLoading>
				<ClockPicker
					startTime={newSchedules[selectedScheduleIndex].time}
					onChange={(time) => {
						let aux = newSchedules;
						aux[selectedScheduleIndex].time = time;
						if (aux[selectedScheduleIndex]?.professional && aux[selectedScheduleIndex]?.date) {
							setLoading(true);
							validateReservationDate();
						} else {
							setOpenTimeModal(false);
						}
					}}></ClockPicker>
			</Dialog>

			<Header title={(updateBookingId?"Editar":"Nova")+" Reserva"} onBack={() => navigate("/schedule")} />

			{!selectedClient ? (
				<>
					<div style={{ alignItems: "center", textAlign: "center", backgroundColor: "#eeeeee", padding: "30px" }}>
						<Typography
							style={{
								color: "gray",
								marginBottom: "20px",
								fontSize: "16px",
							}}>
							Adicione ou selecione clientes para fazer uma reserva
						</Typography>
						<TextField
							onChange={(e) => {
								setSearchClients(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Search
											style={{
												cursor: "pointer",
												width: "calc(1vw + 20px)",
												height: "calc(1vw + 20px)",
												margin: "0.5vw",
											}}
										/>
									</InputAdornment>
								),
							}}
							placeholder={"Procurar clientes..."}
							className={classes.roundedTextField}
							style={{
								width: "400px",
							}}></TextField>
					</div>

					<div style={{ justifyContent: "space-between", textAlign: "center" }}>
						<Button
							onClick={() => {
								setOpenClientModal(true);
							}}
							style={{
								backgroundColor: "#eeeeee",
								color: "gray",
								textTransform: "none",
								margin: "1.5vw",
								width: "400px",
								height: "70px",
								maxHeight: "150px",
								boxShadow: "0px 0px 5px #888888",
								alignItems: "center",
							}}>
							<AddIcon
								style={{
									color: "white",
									backgroundColor: "3689ea",
									textTransform: "none",
									borderRadius: "30px",
									width: "32px",
									height: "32px",
									margin: "10px",
								}}></AddIcon>
							<Typography style={{ fontSize: "16px" }}>Adicionar cliente manualmente</Typography>
						</Button>
					</div>
				</>
			) : (
				<>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "20px 0",
						}}>
						<Typography
							style={{
								color: "gray",
								position: "relative",
								padding: "0",
								right: "5vw",
								fontSize: "16px",
							}}>
							Cliente:
						</Typography>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								margin: "20px 0",
								gap: "10px",
								borderRadius: 30,
								backgroundColor: "#eeeeee",
								padding: "15px",
								boxShadow: "0px 0px 5px #888888",
								justifyContent: "center",
								position: "relative",
								right: "2.5vw",
							}}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<AccountCircle
									style={{
										color: "lightgray",
										borderRadius: 30,
										width: "30px",
										height: "30px",
									}}></AccountCircle>
								<div style={{ margin: "0 10px" }}>
									<Typography style={{ color: "gray", fontSize: "15px" }}>{selectedClient?.name}</Typography>
									<Typography style={{ color: "gray", fontSize: "15px" }}>{selectedClient?.phone}</Typography>
								</div>
								<Close
									style={{
										color: "lightgray",
										width: "30px",
										height: "30px",
										cursor: "pointer",
									}}
									onClick={() => setSelectedClient(null)}></Close>
							</div>
						</div>
					</div>
					{/* <div
						style={{
							display: "flex",
							justifyContent: "center",
							padding: "1vw",
							backgroundColor: "#eeeeee",
							boxShadow: "0px 0px 5px #888888",
						}}>
						<Typography style={{ color: "gray", fontSize: "22px" }}>Agedamentos da Reserva</Typography>
					</div> */}
					{newSchedules?.length > 0 &&
						newSchedules.map((schedule, index) => {
							return (
								<>
									<div
										style={{
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
											display: "flex",
											padding: "1vw 0",
										}}>
										<Typography style={{ color: "gray", fontSize: "calc(0.5vw + 5px)", marginRight: "0.5vw" }}>
											Agendamento com
										</Typography>

										<div
											style={{
												display: "flex",
												alignItems: "center",
												borderRadius: 30,
												backgroundColor: !schedule.professional ? "#eeeeee" : "#3689ea",
												boxShadow: "0px 0px 5px #888888",
												justifyContent: "center",
												maxWidth: "720px",
												cursor: schedule?.professional ? "default" : "pointer",
												padding: "15px 15px",
											}}>
											{!schedule.professional ? (
												<div
													onClick={() => {
														setSelectedScheduleIndex(index);
														setShowProfessionals(true);
													}}
													style={{
														alignItems: "center",
													}}>
													<Typography
														style={{
															fontSize: "15px",
															color: "gray",
														}}>
														Selecionar um professional
													</Typography>
												</div>
											) : (
												<div style={{ display: "flex", alignItems: "center" }}>
													<AccountCircle
														style={{
															color: "white",
															borderRadius: 30,
															width: "30px",
															height: "30px",
														}}></AccountCircle>
													<div style={{ margin: "0 15px" }}>
														<Typography
															style={{
																color: "white",
																fontSize: "15px",
															}}>
															{schedule.professional?.name}
														</Typography>
														{/* <Typography style={{ color: "white", fontSize: "calc(0.3vw + 5px)" }}>
															{schedule.professional?.phone}
														</Typography> */}
													</div>
													<Close
														style={{
															color: "white",
															width: "30px",
															height: "30px",

															cursor: "pointer",
														}}
														onClick={() => {
															var aux = newSchedules;
															aux[index].professional = null;
															aux[index].service = null;
															updateNewSchedules(aux);
														}}></Close>
												</div>
											)}
										</div>

										<Typography style={{ color: "gray", fontSize: "calc(0.5vw + 5px)", margin: "0 0.5vw" }}>
											para o serviço de
										</Typography>

										<div
											style={{
												display: "flex",
												alignItems: "center",

												borderRadius: 30,
												backgroundColor: !schedule.service
													? schedule?.professional
														? "#eeeeee"
														: "lightgray"
													: "#3689ea",
												boxShadow: "0px 0px 5px #888888",
												justifyContent: "center",
												maxWidth: "720px",
												cursor: schedule.service || !schedule?.professional ? "default" : "pointer",
											}}>
											{!schedule.service ? (
												<div
													onClick={() => {
														if (schedule?.professional && !schedule?.service) {
															setSelectedServices(null);
															setSelectedScheduleIndex(index);
															if (selectedCategory) {
																getServices(index, selectedCategory.id);
															}
															setShowServices(true);
															setSelectedCategory(categories[0])
															getServices(index, categories[0].id)
														}
													}}
													style={{
														alignItems: "center",
														padding: "5px 1.5vw",
													}}>
													<Typography
														style={{
															fontSize: "calc(0.5vw + 5px)",
															color: schedule?.professional ? "gray" : "#eeeeee",
															textAlign: "center",
															maxWidth: "400px",
														}}>
														{schedule?.professional
															? "Selecionar serviço"
															: "Escolha um profissional antes de selecionar um serviço"}
													</Typography>
												</div>
											) : (
												<div style={{ margin: "5px 0 5px 2vw", display: "flex", alignItems: "center" }}>
													<div>
														<Typography style={{ color: "white", fontSize: "calc(0.5vw + 5px)", marginRight: "1vw" }}>
															{schedule.service?.name}
														</Typography>
													</div>
													<Close
														style={{
															marginRight: "0.5vw",
															color: "white",
															cursor: "pointer",
														}}
														onClick={() => {
															let aux = newSchedules;
															aux[index].service = null;
															updateNewSchedules(aux);
														}}></Close>
												</div>
											)}
										</div>

										<Typography
											style={{
												color: "gray",
												fontSize: "calc(0.5vw + 5px)",
												margin: "0 0.5vw",
											}}>
											no dia
										</Typography>

										<div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
											<div style={{ width: "100%", margin: "20px 0", textAlign: "center" }}>
												<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
													<Button
														style={{
															backgroundColor: schedule.date ? "#3689ea" : "#eeeeee",
															borderRadius: 40,
															color: schedule.date ? "white" : "gray",
															fontSize: "calc(0.5vw + 5px)",

															textTransform: "none",
															padding: "0 20px",
															boxShadow: "0px 0px 5px #888888",
														}}
														onClick={() => {
															setSelectedScheduleIndex(index);
															setOpenDateModal(true);
														}}>
														{schedule.date
															? (new Date(schedule.date).getDate() >= 10
																	? new Date(schedule.date).getDate()
																	: "0" + new Date(schedule.date).getDate()) +
															  "/" +
															  (new Date(schedule.date).getMonth() + 1 >= 10
																	? new Date(schedule.date).getMonth() + 1
																	: "0" + (new Date(schedule.date).getMonth() + 1)) +
															  " (" +
															  (new Date(schedule.date).getDate() == new Date(now).getDate() &&
															  new Date(schedule.date).getMonth() == new Date(now).getMonth() &&
															  new Date(schedule.date).getFullYear() == new Date(now).getFullYear()
																	? "Hoje"
																	: convertDayOfWeek(new Date(schedule.date).getDay())) +
															  ")"
															: "--/--"}
													</Button>
													<Typography style={{ color: "gray", fontSize: "calc(0.5vw + 5px)", margin: "0 0.5vw" }}>
														às
													</Typography>
													<Button
														style={{
															backgroundColor: schedule.time ? "#3689ea" : "#eeeeee",
															borderRadius: 40,
															color: schedule.time ? "white" : "gray",
															fontSize: "calc(0.5vw + 5px)",

															textTransform: "none",
															padding: "0 20px",
															boxShadow: "0px 0px 5px #888888",
														}}
														onClick={() => {
															setSelectedScheduleIndex(index);
															setOpenTimeModal(true);
														}}>
														{schedule?.time?.timeString || "-- : -- "}
													</Button>
												</div>
											</div>
										</div>
										{newSchedules.length > 1 && (
											<DeleteForever
												onClick={() => {
													let aux = newSchedules;
													aux.splice(index, 1);
													updateNewSchedules(aux);
												}}
												style={{
													color: "lightgray",
													width: "30px",
													height: "30px)",
													margin: "0 15px",
													position: "relative",
													bottom: "1.5vw",
													cursor: "pointer",
												}}></DeleteForever>
										)}
									</div>
									<Divider></Divider>
								</>
							);
						})}
					<Divider style={{ marginBottom: "20px" }}></Divider>
					<div
						style={{ justifyContent: "center", textAlign: "center", display: "flex", paddingBottom: "20vh" }}
						onClick={() => {
							let aux = newSchedules;
							aux.push({ service: null, professional: null, date: null, time: null });
							updateNewSchedules(aux);
						}}>
						<div
							style={{
								backgroundColor: "#eeeeee",
								borderRadius: "30px",
								boxShadow: "0px 0px 5px #888888",
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								margin: "2vw",
								padding: "10px 20px",
								justifyContent: "space-around",
							}}>
							<AddIcon
								style={{
									color: "lightgray",
									textTransform: "none",
									width: "calc(1vw + 20px)",
									height: "calc(1vw + 20px)",
								}}></AddIcon>
							<Typography style={{ color: "lightgray", fontSize: "calc(0.5vw + 10px)" }}>
								Adicionar outro serviço
							</Typography>
						</div>
					</div>

					<div style={{ position: "fixed", left: "50vw", top: "85vh" }}>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "70px 0 30px 0" }}>
							<Button
								style={{
									borderRadius: 50,
									backgroundColor: "#3689ea",
									boxShadow: "0px 0px 5px #888888",
									padding: "10px 20px",
									width: "300px",
									cursor: "pointer",
									color: "white",
									fontSize: "22px",
									textTransform: "none",
									position: "absolute",
									boxShadow: "0px 0px 5px #888888",
								}}
								onClick={() => {
									let valid = true;

									for (let i = 0; i < newSchedules.length; i++) {
										let ns = newSchedules[i];
										if (!ns.service || !ns.professional || !ns.date || !ns.time) {
											valid = false;
											break;
										}
									}

									if (valid) {
										saveReservation();
									} else {
										setInfo("Preencha todos os campos antes de salvar a reserva.");
										setOpenInfoModal(true);
									}
								}}>
								Salvar Reserva
							</Button>
						</div>
					</div>
				</>
			)}

			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div>
					{!selectedClient &&
						clients &&
						clients.map((client) => {
							if (
								searchClients.trim() === "" ||
								client.name.toLowerCase().includes(searchClients.trim().toLowerCase())
							) {
								return (
									client && (
										<div
											onClick={() => setSelectedClient(client)}
											style={{ cursor: "pointer", width: "calc(24vw + 20px)" }}>
											<Divider style={{ margin: "20px 0" }}></Divider>
											<div style={{ display: "flex", margin: "20px 40px" }}>
												<div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
													<div style={{ display: "flex", width: "100%" }}>
														<AccountCircle
															style={{
																color: "lightgray",
																borderRadius: 30,
																width: "60px",
																height: "60px",
															}}></AccountCircle>
														<div style={{ width: "15vw" }}>
															<Typography
																style={{
																	color: "gray",
																	marginTop: "10px",
																	marginLeft: "10px",
																	fontSize: "15px",
																}}>
																{client.name}
															</Typography>
															<Typography style={{ color: "gray", fontSize: "15px", marginLeft: "10px" }}>
																{client.phone}
															</Typography>
														</div>
													</div>
													{client.isUser && (
														<Logo
															style={{
																width: "60px",
															}}></Logo>
													)}
												</div>
											</div>
										</div>
									)
								);
							}
						})}
				</div>
			</div>
		</>
	);
};

export default NewReservation;
