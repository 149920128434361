import React, { useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../../styles/calendarTimeline/calendarTimeline.scss';
import CustomToolbar from './toolbarComponent';
import EventModal from './eventModal';
import EditBookingModal from './editBookingModal';
import ScheduleBreakModal from './scheduleBreakModal';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const formats = {
  timeGutterFormat: (date, culture, localizer) =>
    moment(date).format('HH:mm'), //Transformar horário no formato 24h
  eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
    `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`,
};

const eventPropGetter = (event, start, end, isSelected) => {
  const backgroundColor = event.type === 'break' ? '#d3d3d3' : event.color || '#3689ea';
  const style = {
    backgroundColor,
    borderRadius: '10px',
    border: 'none',
    padding: '0.5rem',
    color: 'white',
    fontSize: event.type === 'break' ? '18px' : '',
  };
  return {
    style,
  };
};

const CalendarTimeline = ({ events, date, setPageType, breaks, onNavigate }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBreak, setSelectedBreak] = useState(null); // Armazena o break selecionado
  const [isBreakModalOpen, setIsBreakModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState('event'); // 'event' or 'edit'
  const [currentView, setCurrentView] = useState("day");

  const handleViewChange = (view) => {
		setCurrentView(view);
	};

  const allEvents = [
    ...events.filter(event => event.status !== 'inactive' && event.status !== 'cancelled'),
    ...breaks.map(breakItem => ({
      ...breakItem,
      type: 'break',
      title: breakItem.title || 'Break',
    })),
  ];

  const handleEdit = () => {
    setCurrentModal('edit');
  };

  const handleBackToEvent = () => {
    setCurrentModal('event');
  };

  const handleBreakClick = (breakItem) => {
    setSelectedBreak(breakItem); // Define o break selecionado
    setIsBreakModalOpen(true); // Abre o modal
  };

  const handleCloseBreakModal = () => {
    setSelectedBreak(null); // Limpa o break selecionado
    setIsBreakModalOpen(false); // Fecha o modal
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <DragAndDropCalendar
        date={date}
        defaultDate={moment().toDate()}
        defaultView={'day'}
        view={currentView}
        views={["month", "week", "agenda", "day"]}
        messages={{
          noEventsInRange: "Nenhum evento para mostrar nesta data. Aproveite o tempo livre!",
        }}
        events={allEvents}
        localizer={localizer}
        formats={formats}
        resizable
        components={{
					toolbar: (props) => (
						<CustomToolbar
							{...props}
							onNavigate={onNavigate}
							onViewChange={handleViewChange}
							currentView={currentView}
            />
          ),
        }}
        eventPropGetter={eventPropGetter}
        className="CalendarTimeline"
        onSelectEvent={(event) => {
          if (event.type === 'break') {
            const breakItem = breaks.find((b) => b.id === event.id); // Identifica o break correspondente
            handleBreakClick(breakItem);
          } else {
            handleEventClick(event);
          }
        }}
      />

      {isModalOpen && (
        <>
          {currentModal === 'event' && (
            <EventModal
              event={selectedEvent}
              onClose={handleCloseModal}
              onEdit={handleEdit}
              setPageType={setPageType}
            />
          )}
          {currentModal === 'edit' && (
            <EditBookingModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onBack={handleBackToEvent}
              event={selectedEvent}
              setPageType={setPageType}
            />
          )}
        </>
      )}

      {isBreakModalOpen && (
        <ScheduleBreakModal onClose={handleCloseBreakModal} breakData={selectedBreak} />
      )}
    </div>
  );
};

export default CalendarTimeline;
