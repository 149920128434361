import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import { Container, InputAdornment, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import ButtonCommom from "../common/Button";
import sectorService from "../../services/sectorService";
import CoreContainer from "../common/new/container";
import CoreAppBar from "../common/new/appBar";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";

const SelectSector = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();	
	const [loading, setLoading] = useState(true);
	const { fullName, email, businessName, phone } = location?.state || {};
	const [sectors, setSectors] = useState([]);

	useEffect(() => {
		setLoading(true);
		sectorService.getSectors().then((res) => {
			setSectors(res.data.sort((a, b) => a.name.localeCompare(b.name)));
			setLoading(false)
		});
	}, []);

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<SpinnerLoading loading={loading}></SpinnerLoading>
			<CoreAppBar progress={75} />

			<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight:"bold" }}>Qual sua área de atuação?</Typography>

			<Typography style={{ color: "#9C9C9C", fontSize: "16px" }}>
				Informe a sua área de atuação para que possamos prosseguir o cadastro com as opções mais adequadas a sua
				realidade.
			</Typography>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(2, 1fr)",
					gap: "16px",
					width: "100%",
					paddingRight: "16px"
				}}>
				{sectors.map((sector) => (
					<div
						style={{
							backgroundColor: "#F1F1F1",
							padding: "8px 16px",
							borderRadius: "8px",
							cursor: "pointer",
							height: window.innerWidth < window.innerHeight?"60px":"30px",
							display:"flex",
							justifyContent:"center",
							alignItems:"center",
						}}
						onClick={() =>
							navigate("/selecionar-categorias", {
								state: { phone, fullName, email, businessName, sectorId: sector.id },
							})
						}>
						<Typography style={{ color: "#3E3E3E", fontSize: "18px", textAlign:"center" }}>{sector.name}</Typography>
					</div>
				))}
			</div>
		</CoreContainer>
	);
};

export default SelectSector;
