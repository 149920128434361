export const GET_BUSINESS = "GET_BUSINESS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_DATED_BOOKINGS = "GET_DATED_BOOKINGS";
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";
export const GET_BOOKING_TRANSACTION = "GET_BOOKING_TRANSACTION";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_SECTOR = "GET_SECTOR";
export const GET_PROFESSIONAL_SERVICES = "GET_PROFESSIONAL_SERVICES";
export const SET_ME = 'SET_ME';
export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA';
export const SET_SUBSCRIPTION_CURRENT = 'SET_SUBSCRIPTION_CURRENT';
export const UPDATE_BUSINESS_ADDRESS = 'UPDATE_BUSINESS_ADDRESS';

export default {
	GET_BUSINESS,
	GET_NOTIFICATIONS,
	GET_BOOKINGS,
	GET_DATED_BOOKINGS,
	GET_PAYMENT_LIST,
	GET_BOOKING_TRANSACTION,
	GET_CLIENTS,
	GET_SECTOR,
	GET_PROFESSIONAL_SERVICES,
	SET_ME,
	SET_SUBSCRIPTION_DATA,
	SET_SUBSCRIPTION_CURRENT,
	UPDATE_BUSINESS_ADDRESS,
	SET_BUSINESS_INFO,
};
