import React from 'react';
import BusinessProfile from '../../components/businessProfile';
import OpeningHours from '../../components/openingHours';
import ListClients from '../../components/listClients';
import Services from '../../components/services';

export default function SchedulePages ({ type }) {
  if (type === 'businessProfile') return <BusinessProfile />
  if (type === 'openingHours') return <OpeningHours />
  if (type === 'listClients') return <ListClients />
  if (type === 'services') return <Services />

  return null;
}