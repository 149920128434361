import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
	return {
		servicesHeader: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			paddingLeft: "2.5rem",
			height: "5.4rem",
		},
		servicesTitleHeader: {
			fontSize: "16px",
			color: "#4E5461",
			fontWeight: "bold",
		},
		servicesContainer: {
			flex: 1,
			overflow: "auto",
		},
		servicesRegisterContainer: {
			display: "flex",
			width: "100%",
		},
		// main
		serviceMain: {
			[theme.breakpoints.down("md")]: {
				display: "flex",
				justifyContent: "center",
			},
		},
		containerMain: {
            [theme.breakpoints.down("md")]: {
				width: "100%",
                padding: "1rem"
			},
            width: "50%",
			display: "flex",
			flexDirection: "column",
			gap: "1rem",
			padding: "3rem",
		},
		borderRadiusAccordion: {
			border: "1px solid #A5A5A5",
			borderRadius: "5px",
			padding: "0px",
		},
		accordionSummaryContainer: {
			color: "#4E5461",
			fontWeight: "500",
            fontSize: "16px",
            [theme.breakpoints.down("md")]: {
                fontSize: "12px"
            }
		},
		accordionDetailsContainer: {
			padding: "0px",
			backgroundColor: "#eeeeee",
		},
		serviceTreeBackground: {
			backgroundColor: "#eeeeee",
		},
		serviceTreeContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			color: "#4E5461",
			borderTop: "1px solid #A5A5A5",
		},
		serviceTreeDetails: {
			marginLeft: "10px",
		},
		serviceNameCustom: {
			color: "#4E5461",
            fontSize: "16px",
            [theme.breakpoints.down("md")]: {
                fontSize: "12px"
            }
		},
        serviceNameDetailsCustom: {
			width: "100%",
			display: "flex",
			alignItems: "end",
			justifyContent: "start",
            color: "#3688EB",
            fontSize: "16px",
            paddingRight: "15px",
            [theme.breakpoints.down("md")]: {
                fontSize: "12px"
            }
        },
		hoverbuttonSubmit: {
			borderRadius: "8px",
			fontSize: "12px",
		},
		alertContainer: {
			position: "fixed",
			bottom: "0px",
			right: "0px",
			padding: "3rem",
			[theme.breakpoints.down]: {
				padding: "1rem"
			}
		}
	};
});