import React, { useEffect, useState, Fragment } from "react";
import { Modal, Alert, Stack } from "@mui/material";
import { Box } from "@material-ui/core";
import { ArrowForwardIos, Close } from "@material-ui/icons";
import {
	getBookingTransactionById,
	getBookingPaymentDetailsById,
	cancelSchedule,
} from "../../../services/scheduleService";
import moment from "moment";
import styled from "styled-components";
import GradientLoading from "../../common/GradientLoading/gradientLoading.jsx";
import "../../../styles/eventModal/index.scss";
import { useBusiness } from "../../../context/business.context";

const EventModal = ({ event, onClose, onEdit, reload }) => {
	const [loading, setLoading] = useState(true);
	const [paymentTransaction, setPaymentTransaction] = useState(null);
	const [bookingTransaction, setBookingTransaction] = useState(null);
	const [internalNote, setInternalNote] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const { setPageType } = useBusiness();

	const paymentVerification = (p) => {
		let paymentAntecipated;
		let paymentCheckout;
		const paymentTypesValidation = {
			cash: "Dinheiro",
			credit: "Cartão de crédito",
			creditprit: "Cartão de crédito",
			debit: "Cartão de débito",
			debitprit: "Cartão de débito",
			pixprit: "Pix",
			mbwayprit: "Mb-Way",
			undefined: "Prit Pay",
		};
		const descriptionValidation = [
			"PAGAMENTO DE ADIANTAMENTO DE RESERVA PERCENTUAL",
			"PAGAMENTO DE ADIANTAMENTO DE RESERVA FIXO",
			"PAGAMENTO DE ADIANTAMENTO DE RESERVA TOTAL",
		];
		if (p?.payments) {
			paymentAntecipated = p.payments?.find(
				(payment) =>
					(payment.payment_type === "credit" || payment.payment_type === "pix") &&
					payment.payments_description?.some((paymentDescription) =>
						descriptionValidation.includes(paymentDescription.description)
					)
			);

			paymentCheckout = p.payments?.find((v) =>
				v.payments_description.some(
					(paymentDescription) => !descriptionValidation.includes(paymentDescription.description)
				)
			);
		}

		const data = {
			discount: {
				value: p.total_discount,
				date: paymentAntecipated
					? moment(paymentAntecipated.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
			},
			paymentAntecipated: {
				exists: paymentAntecipated,
				date: paymentAntecipated
					? moment(paymentAntecipated.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
				value: paymentAntecipated ? paymentAntecipated.payments_description[0].payment_value : 0,
			},
			paymentCheckout: {
				exists: paymentCheckout,
				text: `${paymentCheckout ? paymentTypesValidation[paymentCheckout.payment_type] : ""} 'no checkout'`,
				date: paymentCheckout
					? moment(paymentCheckout.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
				value: paymentCheckout && paymentCheckout.payments_description[0].payment_value,
			},
			pritpayAvaliable: p.total_available > 0 && {
				text: ("Prit Pay lançado em {{date}}", { date: moment(p.total_available_date).format("DD/MM") }),
				value: p.total_available,
			},
			totalToPaid: p.balance_to_pay,
			totalServices: p.total_services,
			totalRefunded: p.total_refunded,
		};
		return data;
	};

	const bookingStatusStyles = (bookingPaymentStatus) => {
		switch (bookingPaymentStatus) {
			case "pending":
				return { className: "pendingStatus", label: "Pendente" };
			case "cancelled":
				return { className: "cancelledStatus", label: "Cancelado" };
			case "completed":
				return { className: "completedStatus", label: "Pago" };
			case "partial":
				return { className: "partialStatus", label: "Parcial" };
			case "refuse":
				return { className: "refuseStatus", label: "Recusado" };
			case "active":
				return { className: "activeStatus", label: "Agendado" };
			case "inactive":
				return { className: "inactiveStatus", label: "Inativo" };
			case "pending_accept":
				return { className: "pendingAcceptStatus", label: "Pendente de Aceitação" };
			case "requested":
				return { className: "requestedStatus", label: "Solicitado" };
			case "accept":
				return { className: "acceptStatus", label: "Aceito" };
			case "refund":
				return { className: "refundStatus", label: "Reembolsado" };
			default:
				return { className: "defaultStatus", label: "Indefinido" }; //default case
		}
	};

	const formatedPrice = (value) => {
		const price = (value / 100).toFixed(2).replace(".", ",");
		return price;
	};

	const formatedData = (startTime, endTime) => {
		const formattedStart = moment(startTime).format("DD/MM/YYYY - HH:mm");
		const formattedEnd = moment(endTime).format("HH:mm");
		return `${formattedStart} até às ${formattedEnd}`;
	};

	const handleEditBooking = () => {
		const paymentsValidationToEdit = ["completed", "partial", "cancelled"];
		if (bookingTransaction && !paymentsValidationToEdit.includes(bookingTransaction?.paymentStatus)) {
			onEdit();
		} else {
			setErrorMessage("Não é possível fazer a edição da Reserva, verifique o status da reserva.");
		}
	};

	useEffect(() => {
		if (event) {
			const fetchPaymentAndTransaction = async () => {
				try {
					const [bookingTransactionResult, paymentTransactionResult] = await Promise.all([
						getBookingTransactionById(event?.bookingTransactionId),
						getBookingPaymentDetailsById(event?.bookingTransactionId),
					]);
					const data = paymentVerification(paymentTransactionResult.data);
					setPaymentTransaction({ data: paymentTransactionResult.data, filteredData: data });
					setBookingTransaction(bookingTransactionResult.data);
					setInternalNote(bookingTransactionResult.data?.internalNote || "");
				} catch (error) {
					console.log(error);
					setLoading(false);
					onClose();
				} finally {
					setLoading(false);
				}
			};
			fetchPaymentAndTransaction();
		} else {
			setLoading(false);
			onClose();
		}
	}, []);

	const handleCancel = async () => {
		try {
			setLoading(true);
			const data = {
				id: event?.bookingTransactionId,
				paymentStatus: "cancelled",
				cancellationReason: "business",
			};
			await cancelSchedule(event?.bookingTransactionId, data);
			window.alert("O cancelamento foi realizado");
			if (reload) {
				reload();
			} else {
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
			window.alert("Não foi possível realizar o cancelamento");
		} finally {
			setLoading(false);
		}
	};

	if (loading) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100vw",
					height: "100vh",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					zIndex: 999,
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<GradientLoading />
				</Box>
			</div>
		);
	}

	if (bookingTransaction) {
		const booking = bookingTransaction.bookings[0];

		const bookingStatus = bookingStatusStyles(booking?.status);
		const paymentStatus = bookingStatusStyles(bookingTransaction.paymentStatus);

		const SyledLogo = styled.div`
			background-color: #d9d9d9;
			background-size: cover;
			background-position: center;
			background-image: url("${(props) => props.backgroundImage || "none"}");
			width: 50px;
			height: 50px;
			border-radius: 100%;
			margin-right: 10px;
		`;

		return (
			<Modal open={true} onClose={onClose} aria-labelledby="Detalhes da Reserva" className="containerModal">
				<Box className="modal">
					<div className="headerModal">
						<div className="headerTitle">
							<Close onClick={onClose} style={{ fontSize: "1.5rem", cursor: "pointer", padding: "0.6rem" }} />
							<p>Detalhes da Reserva</p>
						</div>
						{onEdit && (
							<p className="editButton" onClick={handleEditBooking}>
								Editar
							</p>
						)}
					</div>
					{errorMessage && (
						<Stack sx={{ width: "100%" }}>
							<Alert severity="error" onClose={() => setErrorMessage("")}>
								{errorMessage}
							</Alert>
						</Stack>
					)}
					<section className="ownerSection" onClick={() => setPageType("businessProfile")}>
						<div className="ownerContainerInfo">
							<SyledLogo backgroundImage={booking.business.thumbnailUrl} />
							<div className="ownerData">
								<p>{bookingTransaction.user.name}</p>
								<p>{bookingTransaction.user.phone}</p>
							</div>
						</div>
						<ArrowForwardIos style={{ fontSize: "1rem", cursor: "pointer", padding: "0.6rem" }} />
					</section>
					<div>
						<section className="bookingDetails">
							<div className="alignData">
								<p className="bold">Reserva</p>
								<p className={bookingStatus.className}>{bookingStatus.label}</p>
							</div>
							<div className="alignData">
								<p>Data e horário:</p>
								<p>{formatedData(booking?.startTime, booking?.endTime)}</p>
							</div>
							<div className="alignData">
								<p>Serviço:</p>
								<p>{booking?.service?.name}</p>
							</div>
							<div className="alignData">
								<p>Profissional:</p>
								<p>{booking?.professional?.name}</p>
							</div>
						</section>

						<section className="bookingDetails">
							<div className="alignData">
								<p className="bold">Pagamento</p>
								<p className={paymentStatus.className}>{paymentStatus.label}</p>
							</div>
							<div className="alignData">
								<p>Desconto:</p>
								<p>R$ {formatedPrice(paymentTransaction?.filteredData?.discount?.value)}</p>
							</div>
							<div className="alignData border">
								<p>Pritpay antecipado:</p>
								<p>R$ {formatedPrice(paymentTransaction?.filteredData?.paymentAntecipated?.value)}</p>
							</div>
							<div className="alignData" style={{ marginTop: "1rem" }}>
								<p className="bold">Total a Pagar</p>
								<p className="bold">R$ {formatedPrice(paymentTransaction?.filteredData?.totalToPaid)} </p>
							</div>
						</section>
						<div>
							<button className="cancelButton" style={{ cursor: "pointer" }} onClick={handleCancel}>
								<p>Cancelar</p>
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		);
	} else {
		return null;
	}
};

export default EventModal;
