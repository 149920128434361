import * as Yup from "yup";

export const openingHoursSchema = Yup.object({
	timelapse: Yup.string().required("Campo obrigatório"),
	availability: Yup.string().required("Campo obrigatório"),
	earlyBooking: Yup.string().required("Campo obrigatório"),
});

export const initialOpeningHoursForm = {
  id: "",
  timelapse: "",
  availability: "",
  earlyBooking: "",
};

export const initialDaysList = [
  {
    id: 0,
    isChecked: false,
    name: "Domingo",
    intervals: [
      { id: 1, start: "08:00", end: "18:00" }
    ],
  },
  {
    id: 1,
    isChecked: false,
    name: "Segunda-feira",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  },
  {
    id: 2,
    isChecked: false,
    name: "Terça-feira",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  },
  {
    id: 3,
    isChecked: false,
    name: "Quarta-feira",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  },
  {
    id: 4,
    isChecked: false,
    name: "Quinta-feira",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  },
  {
    id: 5,
    isChecked: false,
    name: "Sexta-feira",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  },
  {
    id: 6,
    isChecked: false,
    name: "Sábado",
    intervals: [
      { id: 1, start: "", end: "" }
    ],
  }
];

export const timeLapseList = [
  { value: 1, label: "De acordo com a duração do serviço" },
  { value: 15, label: "De 15 em 15 minutos" },
  { value: 30, label: "De 30 em 30 minutos" },
  { value: 60, label: "De 1 hora em 1 hora" },
]

export const availabilityTimeList = [
  { value: "1|weeks", label: "1 Semana" },
  { value: "2|weeks", label: "2 Semanas" },
  { value: "3|weeks", label: "3 Semanas" },
  { value: "4|weeks", label: "4 Semanas" },
  { value: "1|months", label: "1 Mês" },
  { value: "2|months", label: "2 Mês" },
  { value: "3|months", label: "3 Mês" },
  { value: "4|months", label: "4 Mês" },
  { value: "5|months", label: "5 Mês" },
  { value: "6|months", label: "6 Mês" },
  { value: "7|months", label: "7 Mês" },
  { value: "8|months", label: "8 Mês" },
  { value: "9|months", label: "9 Mês" },
  { value: "10|months", label: "10 Mês" },
  { value: "11|months", label: "11 Mês" },
  { value: "12|months", label: "12 Mês" },
];