import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { Divider } from "@mui/material";

import MenuItem from "./components/MenuItem";
import LinkItem from "./components/LinkItem";
import Calendar from "../common/Calendar";
import useStyles from "./styles";
import "../../styles/schedule/index.scss";
import { useBusiness } from "../../context/business.context";
import LogoutDialog from "./components/LogoutDialog";
import clsx from "clsx";
import { isMobile } from "../../utils/isMobile";

export default function Sidebar() {
	const business = useSelector((store) => store.business.business);
	const {
		date,
		setShowCalendar,
		handleSetDate,
		setPageType,
		businessData,
		Menus,
		isOpenModalLogout,
		setIsOpenModalLogout,
		reservationDates,
		showMenu,
		toggleMenu,
		scheduleBreakDates,
	} = useBusiness();
	const classes = useStyles();

	const validateShowMenu = () => {
		if (isMobile()) {
			return showMenu;
		}
		return true;
	};

	return (
		<>
			<div className={clsx("calendar", showMenu && "sidebar")}>
				<div className="header-menu">
					<button
						className={clsx("date-header-name", showMenu && "show")}
						onClick={() => {
							if (isMobile()) toggleMenu();
							setPageType("root");
						}}>
						<SyledLogo backgroundImage={business?.thumbnailUrl} />

						<span className="menu-business-name">{business?.name}</span>
					</button>

					<button
						className={clsx("close-menu", showMenu ? "show" : "hiden")}
						onClick={() => {
							toggleMenu();
						}}>
						<Close />
					</button>
				</div>
				<Divider />

				<div className={clsx("calendar-header", showMenu && "hiden")}>
					<Calendar
						update
						date={date}
						onChange={() => setShowCalendar(false)}
						setDate={(e) => handleSetDate(e)}
						daysToNotify={reservationDates}
						daysBreakNotify={scheduleBreakDates}
					/>
				</div>
				<div
					className={clsx(`date-header ${classes.menu}`, validateShowMenu() ? "show" : "hiden")}
					style={{
						flexDirection: "column",
						alignItems: "flex-start",
						marginBottom: "10px",
					}}>
					{businessData && businessData.dynamicLink.length && <LinkItem dynamicLink={businessData.dynamicLink} />}
					{Menus.map((menu, index) => (
						<MenuItem key={index} title={menu.title} icon={menu.icon} action={menu.action} />
					))}
				</div>
			</div>
			{isOpenModalLogout && (
				<LogoutDialog isOpen={isOpenModalLogout} handleClose={() => setIsOpenModalLogout(!isOpenModalLogout)} />
			)}
		</>
	);
}

const SyledLogo = styled.div`
	background-color: #d9d9d9;
	background-image: url("${(props) => props.backgroundImage || "none"}");
	background-size: cover;
	background-position: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px;
`;
