import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import { MenuItem, Select, TextField, Typography, Dialog, Divider, Button } from "@material-ui/core";
import { useLocation } from "react-router";
import { sendMsgWavy } from "../../services/loginService";
import { trimTelephoneNumber } from "../../utils";
import CoreContainer from "../common/new/container";
import CoreAppBar from "../common/new/appBar";
import CoreButton from "../common/new/button";
import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraPOR from "assets/icons/POR.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraANG from "assets/icons/ANG.png";
import BandeiraFRA from "assets/icons/FRA.png";
import {
	telephoneMask,
	telephoneMaskPOR,
	telephoneMaskEUA,
	telephoneMaskING,
	telephoneMaskANG,
	telephoneMaskFRA,
} from "utils";

const Login = () => {
	const classes = useStyles();
	const location = useLocation();

	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [phoneDdi, setPhoneDdi] = useState(1);
	const [modalMessage, setModalMessage] = useState("");
	const [openModal, setOpenModal] = useState(false);

	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const businessNameRef = useRef(null);
	const telephoneRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				const button = document.getElementById("confirmButton");
				if (button) {
					button.click();
				}
			}
		}
	};

	const handleChangeType = (e) => {
		setTelephoneNumber("");
		setPhoneDdi(e.target.value);
	};

	const getDdiNumber = () => {
		const ddiMap = {
			1: "55",
			2: "351",
			3: "1",
			4: "44",
			5: "244",
			6: "33",
		};
		return ddiMap[phoneDdi] || "55";
	};

	const handleChange = (e) => {
		const input = e.target.value;
		const previousLength = telephoneNumber.length;
		const currentLength = input.length;

		if (currentLength < previousLength) {
			setTelephoneNumber(input);
			return;
		}

		const maskMap = {
			1: telephoneMask,
			2: telephoneMaskPOR,
			3: telephoneMaskEUA,
			4: telephoneMaskING,
			5: telephoneMaskANG,
			6: telephoneMaskFRA,
		};

		const maskFunction = maskMap[phoneDdi] || telephoneMask;
		maskFunction(e);
		setTelephoneNumber(e.target.value);
	};

	const sendCode = () => {
		if (telephoneNumber) {
			sendMsgWavy("+" + getDdiNumber() + trimTelephoneNumber(telephoneNumber))
				.then(() => {})
				.catch((error) => {
					setModalMessage(error.message);
					setOpenModal(true);
				});
		}
	};

	useEffect(() => {
		localStorage.setItem("logged", "false");
	}, []);

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const isNewBusiness = () => !!location?.state?.newBusiness;

	return (
		<CoreContainer style={{ gap: "16px" }}>
			<CoreAppBar progress={isNewBusiness() ? 25 : null} />

			<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>
				{isNewBusiness() ? "Crie sua conta e comece a transformar seu negócio" : "Informe seu telefone"}
			</Typography>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Typography>
				<Divider />
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			{isNewBusiness() && (
				<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
					<TextField
						inputRef={fullNameRef}
						value={fullName}
						variant="outlined"
						label="Nome completo"
						onChange={(e) => setFullName(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e, emailRef)}
					/>

					<TextField
						inputRef={emailRef}
						value={email}
						variant="outlined"
						label="E-mail"
						onChange={(e) => setEmail(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e, businessNameRef)}
						type="email"
					/>

					<TextField
						inputRef={businessNameRef}
						value={businessName}
						variant="outlined"
						label="Nome do seu negócio"
						onChange={(e) => setBusinessName(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e, telephoneRef)}
					/>
				</div>
			)}

			<div style={{ display: "flex", gap: "16px", width: "100%" }}>
				<Select
					style={{
						paddingLeft: "16px",
						backgroundColor: "#D9D9D9",
						color: "#3E3E3E",
						borderRadius: "8px",
						borderBottom: "none",
					}}
					value={phoneDdi}
					onChange={handleChangeType}>
					<MenuItem value={1}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraBRA} alt="BandeiraBRA" /> +55
					</MenuItem>
					<MenuItem value={2}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraPOR} alt="BandeiraPOR" /> +351
					</MenuItem>
					<MenuItem value={3}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraEUA} alt="BandeiraEUA" /> +1
					</MenuItem>
					<MenuItem value={4}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraING} alt="BandeiraING" /> +44
					</MenuItem>
					<MenuItem value={5}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraANG} alt="BandeiraANG" /> +244
					</MenuItem>
					<MenuItem value={6}>
						<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraFRA} alt="BandeiraFRA" /> +33
					</MenuItem>
				</Select>
				<TextField
					inputRef={telephoneRef}
					id="inputTelephoneNumber"
					value={telephoneNumber}
					variant="outlined"
					label="Ex: (11) 9 9300-0000"
					onChange={handleChange}
					onKeyDown={(e) => handleKeyDown(e, null)}
					style={{ flex: 1 }}
					type="tel"
					inputProps={{
						pattern: "[0-9]*",
						inputMode: "numeric",
					}}
				/>
			</div>

			<Typography style={{ color: "#9C9C9C", fontSize: "16px", textAlign: "center" }}>
				Enviaremos um código via SMS para validar seu número de telefone.
			</Typography>

			<div style={{ flex: 1 }}></div>

			<CoreButton
				id="confirmButton"
				disable={
					isNewBusiness()
						? telephoneNumber.length < 15 || fullName === "" || !validateEmail(email) || businessName === ""
						: telephoneNumber.length < 15
				}
				onClick={sendCode}
				toCode={true}
				params={{
					telephoneNumber: telephoneNumber,
					ddi: getDdiNumber(),
					fullName,
					email,
					businessName,
				}}
			/>
		</CoreContainer>
	);
};

export default Login;
