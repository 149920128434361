import React, { useState } from "react";
import clsx from "clsx";
import { getDay } from "date-fns";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { moneyRender, convertDayOfWeek } from "../../utils";
import EventModal from "components/common/CalendarTimeline/eventModal";

export default function BoxSchedule({ data = [], loading, reloadBooking }) {
	const [selectedEvent, setSelectedEvent] = useState(null);

	if (loading) {
		return (
			<div className="list-schedules none-schedule">
				<div className="loading">
					<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
				</div>
			</div>
		);
	}

	if (data.length === 0) {
		return <div className="list-schedules none-schedule">Não há agendamentos para este cliente.</div>;
	}

	const prepareDate = (value) => {
		const date = new Date(value);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		return day + "/" + month;
	};

	const prepareTime = (initValue, finalValue) => {
		const initDate = new Date(initValue);
		const finalDate = new Date(finalValue);
		return (
			initDate.getHours() + ":" + finalDate.getMinutes() + " - " + finalDate.getHours() + ":" + finalDate.getMinutes()
		);
	};

	const checkStatusCancelled = (status) => {
		if (status === "inactive" || status === "cancelled") {
			return true;
		}
		return false;
	};

	const handleCloseModal = (reload = false) => {
		setSelectedEvent(null);
		if (reload) {
			reloadBooking();
		}
	};

	return (
		<>
			<div className="list-schedules">
				{data.map((booking) => {
					return (
						<button className="box-schedule" key={booking.id} onClick={() => setSelectedEvent(booking)}>
							<div className="schedule-date">
								<div className="schedule-date-title">{convertDayOfWeek(getDay(booking.startTime)).slice(0, 3)}</div>
								<div className="schedule-date-info">{prepareDate(booking.startTime)}</div>
								<div className="schedule-date-time">{prepareTime(booking.startTime, booking.endTime)}</div>
							</div>
							<div className="schedule-infos">
								<div className="schedule-details">
									<div className="schedule-service">{booking.service.name}</div>
									<div className="schedule-professional">Profissional: {booking.professional.name}</div>
									<div className="schedule-status">
										<div
											className={clsx("schedule-badge", !checkStatusCancelled(booking.status) ? "active" : "cancel")}>
											{checkStatusCancelled(booking.status) ? "CANCELADO" : "AGENDADO"}
										</div>
									</div>
								</div>
							</div>
							<div className="schedule-price">
								{booking.pricingType.name} <br /> {moneyRender(booking.business, booking.price)}
							</div>
						</button>
					);
				})}
			</div>
			{selectedEvent && (
				<EventModal
					onClose={() => handleCloseModal(false)}
					event={selectedEvent}
					reload={() => handleCloseModal(true)}
				/>
			)}
		</>
	);
}
