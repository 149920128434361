import React, { useState, useCallback, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Dialog, Divider, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "services/firebase";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import { fireBaseErrors, trimTelephoneNumber } from "../../utils";
import { useMixpanel } from "react-mixpanel-browser";
import { sendMessageWavy, sendMsgWavy, sendTokenFirebase } from "../../services/loginService";
import CoreAppBar from "../common/new/appBar";
import CoreContainer from "../common/new/container";
import CoreButton from "../common/new/button";
import api from "../../services/api";
import businessAction from "../../store/actions/businessAction";
import { useDispatch } from "react-redux";
import { getBusiness } from "../../services/businessService";

const Code = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();
	const dispatch = useDispatch();

	const telephoneNumber = location?.state?.telephoneNumber;
	const ddi = location?.state?.ddi;
	const { fullName, email, businessName } = location?.state || {};

	const [timeCount, setTimeCount] = useState(60);
	const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);
	const [appVerifier, setAppVerifier] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [loading, setLoading] = useState(true);

	const codeInputRefs = useRef([]);

	const addToRefs = (el) => {
		if (el && !codeInputRefs.current.includes(el)) {
			codeInputRefs.current.push(el);
		}
	};

	const sendCode = async () => {
		if (telephoneNumber) {
			try {
				await sendMsgWavy("+" + ddi + trimTelephoneNumber(telephoneNumber));
				setTimeCount(60);
			} catch (error) {
				setModalMessage(error?.message);
				setOpenModal(true);
			}
		}
	};

	const handleVerificationError = (error) => {
		setLoading(false);
		const errorMessages = {
			"Request failed with status code 400": "Erro ao validar o telefone - o código fornecido não é válido",
		};
		setModalMessage(errorMessages[error.message] || fireBaseErrors(error.code));
		resetCodeInputs();
		setOpenModal(true);
	};

	const resetCodeInputs = () => {
		setCodeDigits(["", "", "", "", "", ""]);
		if (codeInputRefs.current[0]) {
			codeInputRefs.current[0].focus();
		}
	};

	const verifyCode = useCallback(
		async (code) => {
			try {
				const phoneNumber = "+" + ddi + trimTelephoneNumber(telephoneNumber);
				const enteredCode = code || codeDigits.join("");

				if (enteredCode.length === 6) {
					const result = await sendMessageWavy(phoneNumber, enteredCode);
					await sendTokenFirebase(auth, result.data.message);

					localStorage.setItem("logged", "true");

					mixpanel.identify(result.data.message.phoneNumber || "", { my_custom_prop: "foo" });
					mixpanel.track("Login - WB", { my_custom_prop: "foo" });

					dispatch(businessAction.getBusiness());

					processUser();
				}
			} catch (error) {
				handleVerificationError(error);
			}
		},
		[codeDigits, ddi, telephoneNumber, dispatch, mixpanel]
	);

	const processUser = async () => {
		try {
			if (fullName && email && businessName) {
				const res = await api.get(
					"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
				);

				if (res?.data?.business?.id) {
					setLoading(false);
					navigate("/menu", { state: { user: res.data.message } });
				} else {
					setLoading(false);
					navigate("/selecionar-setores", {
						state: { fullName, email, businessName, phone: "+" + ddi + trimTelephoneNumber(telephoneNumber) },
					});
				}
			} else {
				const data = await getBusiness();
				if (data) {
					const business = data.data;

					const hasActiveSubscription = business.some((biz) =>
						biz.subscriptions.some((sub) => sub.status === "active")
					);

					if (hasActiveSubscription) {
						setLoading(false);
						navigate("/schedule");
					} else {
						setLoading(false);
						navigate("/menu");
					}
				} else {
					setLoading(false);
					navigate("/menu");
				}
			}
		} catch (err) {
			setLoading(false);
			setOpenModal(true);
			setModalMessage(err.message);
		}
	};

	useEffect(() => {
		if (auth && !appVerifier) {
			const verifier = new RecaptchaVerifier("recaptcha", { size: "invisible" }, auth);
			setAppVerifier(verifier);
			setLoading(false);
		}
	}, [auth, appVerifier]);

	useEffect(() => {
		if (timeCount > 0) {
			const timer = setTimeout(() => setTimeCount(timeCount - 1), 1000);
			return () => clearTimeout(timer);
		}
	}, [timeCount]);

	const handleAutoFill = (e) => {
		const code = e.target.value;
		if (code.length === 6 && /^\d{6}$/.test(code)) {
			const newCodeDigits = code.split("");
			setCodeDigits(newCodeDigits);
			verifyCode(code);

			if (codeInputRefs.current[5]) {
				codeInputRefs.current[5].focus();
			}
		}
	};

	const handleChange = (e, index) => {
		const value = e.target.value;
		if (/^\d$/.test(value)) {
			const updatedCode = [...codeDigits];
			updatedCode[index] = value;
			setCodeDigits(updatedCode);

			if (index < 5 && codeInputRefs.current[index + 1]) {
				codeInputRefs.current[index + 1].focus();
			}

			if (updatedCode.every((digit) => digit !== "")) {
				verifyCode(updatedCode.join(""));
			}
		}
	};

	const handleKeyPress = (e, index) => {
		if (e.key === "Backspace" || e.key === "Delete") {
			e.preventDefault();
			const updatedCode = [...codeDigits];
			if (updatedCode[index] !== "") {
				updatedCode[index] = "";
				setCodeDigits(updatedCode);
			} else if (index > 0) {
				if (codeInputRefs.current[index - 1]) {
					codeInputRefs.current[index - 1].focus();
					const prevCode = [...codeDigits];
					prevCode[index - 1] = "";
					setCodeDigits(prevCode);
				}
			}
		} else if (e.key === "Enter") {
			if (codeDigits.every((digit) => digit !== "")) {
				setLoading(true);
				verifyCode();
			}
		}
	};

	const handlePaste = (e) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData("Text").trim();
		if (/^\d{6}$/.test(pasteData)) {
			const newCodeDigits = pasteData.split("");
			setCodeDigits(newCodeDigits);
			verifyCode(pasteData);

			if (codeInputRefs.current[5]) {
				codeInputRefs.current[5].focus();
			}
		}
	};

	return (
		<>
			<CoreContainer style={{ gap: "16px" }}>
				<CoreAppBar progress={businessName ? 50 : null} />
				<SpinnerLoading loading={loading} />

				<Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>
					Informe o código de confirmação
				</Typography>

				<input
					type="text"
					style={{ position: "absolute", opacity: 0, pointerEvents: "none" }}
					autoComplete="one-time-code"
					onChange={handleAutoFill}
				/>

				<div style={{ width: "100%" }}>
					<div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
						{codeDigits.map((digit, index) => (
							<TextField
								autoFocus={index === 0 && digit === ""}
								key={index}
								id={`codeDigit${index}`}
								className={classes.field}
								inputProps={{
									style: {
										fontSize: "24px",
										padding: "12px 4px",
										textAlign: "center",
									},
									maxLength: 1,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
								value={digit}
								onChange={(e) => handleChange(e, index)}
								onKeyDown={(e) => handleKeyPress(e, index)}
								onPaste={handlePaste}
								inputRef={addToRefs}
								variant="outlined"
							/>
						))}
					</div>

					<Typography style={{ color: "#3E3E3E", fontSize: "16px", textAlign: "center", marginTop: "16px" }}>
						Não recebeu o código?
					</Typography>

					<Typography
						style={{
							color: timeCount === 0 ? "#39ADC4" : "gray",
							fontSize: "16px",
							textAlign: "center",
							cursor: timeCount === 0 ? "pointer" : "default",
						}}
						onClick={() => {
							if (timeCount === 0) {
								sendCode();
								setTimeCount(60);
							}
						}}>
						{timeCount === 0 ? "Reenviar código" : `Reenviar em ${timeCount}s`}
					</Typography>
				</div>

				<div style={{ flex: 1 }}></div>

				<CoreButton
					id="confirmButton"
					onClick={() => {
						if (codeDigits.every((digit) => digit !== "")) {
							setLoading(true);
							verifyCode();
						} else {
							setModalMessage("Por favor, preencha todos os dígitos.");
							setOpenModal(true);
						}
					}}
				/>
			</CoreContainer>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Typography style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Typography>
				<Divider />
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			<div id="recaptcha"></div>
		</>
	);
};

export default Code;
