import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import App from "./views/app/App";

ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
					<App />
			</PersistGate>
		</Provider>,
	document.getElementById("root")
);
