import React from "react";
import CoreContainer from "../../common/new/container";
import { Close, Height } from "@material-ui/icons";
import { Button, Dialog } from "@mui/material";
import { Typography } from "@material-ui/core";
import { useNavigate } from 'react-router';
import { auth } from "services/firebase";
import { logoutUser } from "../../../services/loginService";

const LogoutDialog = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();

  const handleConfirmLogout = () => {
    logoutUser(auth);
    localStorage.clear();
    navigate('/welcome');
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullScreen
      PaperProps={{
        style: { backgroundColor: "transparent" },
      }}>
      <CoreContainer style={{ gap: "16px"}} transparent noHeight>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Typography style={{ fontSize: "1.2rem", color: "#616161" }}>
            Deseja sair da sua conta?
          </Typography>
          <Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={() => handleClose()}></Close>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "",
              color: "#3688EB",
              borderRadius: "8px",
              border: "1px solid #3688EB",
              width: "50%",
              textTransform: "none",
            }}
            onClick={() => handleConfirmLogout()}>
            Sim
          </Button>

          <Button
            variant="outlined"
            style={{
              backgroundColor: "#3688EB",
              color: "#FFFFFF",
              borderRadius: "8px",
              width: "50%",
              textTransform: "none",
            }}
            onClick={() => handleClose()}>
            Não
          </Button>
        </div>
      </CoreContainer>
    </Dialog>
  )
}

export default LogoutDialog;