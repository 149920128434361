import Constants from "../../helpers/Constants";

const initialState = {
  business: null,
  businessInfo: null,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_BUSINESS:
      return {
        ...state,
        business: action.payload.business,
      };
    case Constants.UPDATE_BUSINESS_ADDRESS:
      return {
        ...state,
        business: {
          ...state.business,
          address: action.payload.business.address,
        },
      };
    case Constants.SET_BUSINESS_INFO:
      return {
        ...state,
        businessInfo: action.payload
      };
    default:
      return state;
  }
};

export default businessReducer;
