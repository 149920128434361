import React, { useEffect, useReducer, useState } from "react";
import useStyles from "./style";
import _, { set } from "lodash";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Typography, Divider, Checkbox } from "@mui/material";
import {
	businessServicesLastUpdate,
	checkNewsServices,
	sectorLastUpdate,
	bulkUpdateAsync,
	bulkUpdate,
} from "../../services/ServicesService";
import { moneyRender } from "../utils";
import { useSelector, useDispatch } from "react-redux";
import { updateInfoServices } from "../../store/redux/modules/services";
import {
	resetServices,
	extractBusinessServices,
	updateItemsServices,
} from "../../store/reducers/businessServicesSelectReducer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalServicesEdit from "./components/modalServicesEdit";
import CoreButton from "../common/new/button";
import Alert from "@mui/material/Alert";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";

export default function Services() {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [serviceSelected, setServiceSelected] = useState(null);
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState(false);
	const { business } = useSelector((store) => store.business);

	const { services } = useSelector((store) => store);
	const { itemsServices } = useSelector((store) => store);

	const dispatch = useDispatch();

	// Select the services created by the business and services linked to the coupon.
	const selectedServices = () => {
		resetServices(services.categories, services.services, services.businessServices)(dispatch);
	};

	useEffect(() => {
		if (!_.isEmpty(services.services) && !_.isEmpty(services.categories)) {
			selectedServices();
		}
	}, [services.services, services.categories]);

	useEffect(() => {
		setLoading(true);
		const arrayIds = business.categories.map((item) => item.id);
		businessServicesLastUpdate(business.id, null, {}, false).then((resu) => {
			updateInfoServices(resu.data, null, "BUSINESS_SERVICES_UPDATE_SUCCESS")(dispatch);
		});
		checkNewsServices(arrayIds, null, {}, business.language, false).then((result) => {
			updateInfoServices(result.data, null, "SERVICES_UPDATE_SUCCESS")(dispatch);
		});
		sectorLastUpdate(business.id, null, business.language).then((resul) => {
			const allCategories = resul.data.flatMap((item) => item.categories);
			const sortCategories = allCategories.sort((a, b) => a.name.localeCompare(b.name));
			updateInfoServices(null, sortCategories, "CATEGORIES_UPDATE_SUCCESS")(dispatch);
		});
		setLoading(false);
	}, []);

	function formatDuration(durationInMinutes) {
		const hours = Math.floor(durationInMinutes / 60);
		const minutes = durationInMinutes % 60;

		let formattedDuration = "";

		if (hours > 0) {
			formattedDuration += `${hours}h`;
		}

		if (minutes > 0) {
			formattedDuration += `${minutes}m`;
		}

		return formattedDuration || "0m";
	}

	const handleChange = (item, edit = false) => {
		if (item.items) {
			item.selected = !item.selected;
		}
		const newItems = [...itemsServices.items];
		if (edit) {
			handleEditService(item, newItems);
		} else {
			handleSelectService(item, newItems);
		}

		dispatch(updateItemsServices({ ...itemsServices, items: newItems }));
	};

	const handleSelectService = (item, items) => {
		items.forEach((category) => {
			if (item.items && item.items.length) {
				item.items.forEach((subItem) => {
					if ('selected' in subItem) {
						delete subItem.selected
					} else {
						subItem.selected = true;
					}
				});
			} else if (category.id === item.service.categoryId) {
				const selectedService = category.items.find((x) => x.id === item.id);

				if (selectedService) {
					selectedService.selected = !selectedService.selected;
				} else {
					item.selected = !item.selected;
				}
			}
		});
	};

	const handleEditService = (item, items) => {
		const findItemRecursively = (itemsArray, itemId) => {
			for (const i of itemsArray) {
				if (i.items && i.items.length > 0) {
					const foundItem = findItemRecursively(i.items, itemId);
					if (foundItem) return foundItem;
				} else if (i.id === itemId) {
					return i;
				}
			}
			return null;
		};

		const findCategoryRecursively = (itemsArray, categoryId) => {
			for (const category of itemsArray) {
				if (category.id === categoryId) {
					return category;
				} else if (category.items && category.items.length > 0) {
					const foundCategory = findCategoryRecursively(category.items, categoryId);
					if (foundCategory) return foundCategory;
				}
			}
			return null;
		};

		const category = findCategoryRecursively(items, item.service.categoryId);

		if (category) {
			const selectedService = findItemRecursively(category.items, item.id);

			if (selectedService) {
				selectedService.service = item.service;
				setOpen(false);
				setServiceSelected(null);
			} else {
				console.error("Serviço não encontrado na categoria.");
			}
		} else {
			console.error("Categoria não encontrada.");
		}
	};

	const servicesTree = (services) => {
		return services.map((service) => (
			<div sx={{ backgroundColor: "#eeeeee" }}>
				<div className={classes.serviceTreeContainer}>
					<div>
						<Checkbox
							sx={{ color: "#A5A5A5" }}
							size="small"
							checked={service.selected || false}
							onChange={() => handleChange(service, false)}
						/>
						<span className={classes.serviceNameCustom}>{service.name}</span>
					</div>
					{/* Details */}
					<div
						className={classes.serviceTreeDetails}
						onClick={() => {
							setServiceSelected(service);
							setOpen(true);
						}}>
						<span className={classes.serviceNameDetailsCustom}>
							{service.service ? `${formatDuration(service.service.duration)} - ` : ""}
							{service.service ? `${moneyRender(business, service.service.price / 100)}` : ""}
						</span>
					</div>
				</div>
				<div style={{ paddingLeft: "1rem" }}>{service.items && servicesTree(service.items)}</div>
			</div>
		));
	};

	const categoriesTree = (categories) => {
		return categories.map((categorie) => (
			<Accordion key={categorie.id} className={classes.borderRadiusAccordion}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					className={classes.accordionSummaryContainer}
					sx={{ backgroundColor: "#eeeeee" }}>
					{categorie.name}
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetailsContainer}>
					{servicesTree(categorie.items)}
				</AccordionDetails>
			</Accordion>
		));
	};

	const customAlert = (text) => {
		setAlert(true);
		setAlertMessage(text);
		setTimeout(() => {
			setAlert(false);
		}, 3000);
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const updateServices = extractBusinessServices(itemsServices.items);
			if (business.sectorId === "7a1b72d2-c6a3-4735-aec6-53848362d7c5" || updateServices.length > 999) {
				await bulkUpdateAsync(business.id, {
					businessServices: updateServices,
					grouped: business.sectorId === "7a1b72d2-c6a3-4735-aec6-53848362d7c5" ? false : null,
				}).then(() => {
					customAlert("Serviços atualizados com sucesso");
				});
				updateInfoServices([], null, "BUSINESS_SERVICES_UPDATE_SUCCESS")(dispatch);
			} else {
				await bulkUpdate(business.id, { businessServices: updateServices })
					.then((result) => {
						updateInfoServices(result.data, null, "BUSINESS_SERVICES_UPDATE_SUCCESS")(dispatch);
					})
					.then(() => {
						customAlert("Serviços atualizados com sucesso");
					});
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	return (
		<>
			<SpinnerLoading loading={loading}></SpinnerLoading>
			<div className={classes.servicesContainer}>
				{/* Header */}
				<div className={classes.servicesHeader}>
					<Typography className={classes.servicesTitleHeader}>Serviços</Typography>
					<NotificationsNoneIcon style={{ color: "#787878", paddingRight: "3rem" }} />
				</div>
				<Divider />
				{/* main */}
				<main className={classes.serviceMain}>
					<div className={classes.containerMain}>
						{categoriesTree(itemsServices.items)}
						<CoreButton onClick={() => handleSubmit()} text="Salvar" color="#3689ea" />
					</div>
				</main>
				<div className={classes.alertContainer}>
					{alert && (
						<Alert severity="success" onClose={() => setAlert(false)}>
							{alertMessage}
						</Alert>
					)}
				</div>
			</div>

			{serviceSelected && (
				<ModalServicesEdit
					open={open}
					close={() => setOpen(false)}
					item={serviceSelected}
					handleChange={handleChange}
				/>
			)}
		</>
	);
}
