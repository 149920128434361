import businessService from "../../services/businessService";
import Constants from "../../helpers/Constants";

const getBusiness = () => (dispatch) => {
	businessService.getBusiness().then(({ data }) => {
		dispatch({ type: Constants.GET_BUSINESS, payload: { business: data[0] } });
	});
};

const updateBusinessAddress = (id, address) => async (dispatch) => {
	try {
	  const { data } = await businessService.putBusinessAddress(id, address);
	  dispatch({ type: Constants.UPDATE_BUSINESS_ADDRESS, payload: { business: data } });
	} catch (error) {
	  console.error("Erro ao atualizar o endereço:", error);
	  throw error;
	}
};

const setBusinessInfo = (businessInfo) => ({
	type: Constants.SET_BUSINESS_INFO,
	payload: businessInfo,
})

export default { 
	getBusiness,
	updateBusinessAddress,
	setBusinessInfo
};
