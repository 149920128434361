import api from "./api";

export const checkVacancies = (professionalId,date) => {
	return api.get(
		"professional/" + professionalId + "/vacancies?date=" + date
	);
};

export default {
	checkVacancies,
};
