import React, { useEffect, useState } from "react";
import { Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { searchSchema, initialSearchForm } from "./listclients-schema";

import "./style.css";

import { Add, Search } from "@material-ui/icons";
import ClientSide from "./components/client-side";
import SearchClients from "./components/client-search";
import { getAllClients, getClientsByname } from "../../services/clientService";
import { useSelector } from "react-redux";
import ClientForm from "./components/client-form";
import { isMobile } from "utils/isMobile";
import clsx from "clsx";

export default function ListClients() {
	const [loading, setLoading] = useState(false);
	const [selectedClient, setSelectedClient] = useState(null);
	const [listClients, setListClients] = useState([]);
	const [newClient, setNewClient] = useState(false);
	const { business } = useSelector((store) => store.business);

	const { handleSubmit, register, watch } = useForm({
		resolver: yupResolver(searchSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialSearchForm,
	});

	const searchWatch = watch("search");

	const onSubmit = async (data) => {
		setLoading(true);

		getClientsByname({
			business_id: business.id,
			per_page: 9999,
			page: 1,
			name: data.search,
		})
			.then((response) => {
				setListClients(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log("🚀 ~ onSubmit ~ error", error);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (searchWatch.length === 0) {
			loadClients();
		}
	}, [searchWatch]);

	const loadClients = () => {
		setLoading(true);
		getAllClients(business.id)
			.then((response) => {
				setListClients(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log("🚀 ~ loadClients ~ error", error);
				setLoading(false);
			});
	};

	useEffect(() => {
		loadClients();
	}, []);

	const handleUpdateClient = () => {
		onSubmit({ search: searchWatch });
		setSelectedClient(null);
		setNewClient(false);
	};

	const handleNewClient = () => {
		setSelectedClient(null);
		setNewClient(true);
	};

	const handleSelectClient = (data) => {
		setSelectedClient(data);
		setNewClient(false);
	};

	return (
		<>
			<div className="header-content">
				<Typography className="date-header-title">Clientes</Typography>
			</div>
			<Divider />
			<div className="box">
				<div className={clsx("side-search", { hidden: isMobile() && (selectedClient !== null || newClient) })}>
					<div className="form-search">
						<form onSubmit={handleSubmit(onSubmit)} className="input-search-icon">
							<input {...register("search")} className="input-form-text" type="text" placeholder="Buscar clientes" />
							<button type="submit" className="btn-search">
								<Search style={{ fontSize: "24px", color: "#4E5461" }} />
							</button>
						</form>
					</div>
					<div className="list-clients">
						{loading ? (
							<div className="loading">
								<AutorenewIcon style={{ fontSize: 36 }} className="animate-spin" />
							</div>
						) : (
							<SearchClients
								data={listClients}
								search={searchWatch}
								selectedClient={selectedClient}
								setSelectedClient={handleSelectClient}
							/>
						)}
					</div>
					<button className="add-button" onClick={handleNewClient}>
						<Add style={{ fontSize: "32px", color: "white" }} />
					</button>
				</div>

				{selectedClient && (
					<ClientSide
						data={selectedClient}
						handleBlockUpdate={() => handleUpdateClient()}
						updateClientsList={() => onSubmit({ search: searchWatch })}
						closeClient={() => setSelectedClient(null)}
					/>
				)}
				{newClient && <ClientForm handleUpdate={() => handleUpdateClient()} closeForm={() => setNewClient(false)} />}
			</div>
		</>
	);
}
