import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import { Dialog, Divider } from "@mui/material";
import useStyles from "./styles";
import CalendarTimeline from "../common/CalendarTimeline";
import { getAuth } from "firebase/auth";
import ProfessionalSelect from "../common/ProfessionalSelect";
import api from "../../services/api";
import { getDatedBookings, getBookings } from "../../services/scheduleService";
import AddIcon from "@mui/icons-material/Add";

import NewScheduleDialog from "../newScheduleDialog";

import clientAction from "../../store/actions/clientAction";
import sectorAction from "../../store/actions/sectorAction";
import { useDispatch } from "react-redux";
import businessAction from "../../store/actions/businessAction";
import { fetchPriceTypesSuccess } from "../../store/actions/priceTypesAction";

import "../../styles/schedule/index.scss";
import { useBusiness } from "../../context/business.context";
import Sidebar from "../sidebar";

import SchedulePages from "../../views/SchedulePages";


const Schedule = (props) => {
	const { businessData, setBusinessData, setReservationDates, pageType, date, now, setNow, setHours, setDate, setScheduleBreakDates } = useBusiness();
	const [auth, setAuth] = useState(getAuth());
	const dispatch = useDispatch();
	const classes = useStyles();
	const navigate = useNavigate();
	const [update, setUpdate] = useState(false);

	const { priceTypes } = ((store) => store.priceTypes);

	const [professionals, setProfessionals] = useState([]);
	const [notifications, setNotifications] = useState([]);

	const [loading, setLoading] = useState(false);
	const [newsCount, setNewsCount] = useState(0);
	const [lastReminderCheckDate, setLastReminderCheckDate] = useState(new Date(0));
	const [reservations, setReservations] = useState([]);
	const [scheduleBreak, setScheduleBreak] = useState([]);

	const [selectedBooking, setSelectedBooking] = useState(null);
	const [openDescripition, setOpenDescription] = useState(false);
	const [selectedBookingTransaction, setSelectedBookingTransaction] = useState(null);
	const [selectedPaymentList, setSelectedPaymentList] = useState(null);
	const [filtredProfessional, setFiltredProfessional] = useState(null);
	const [reservationsLoading, setReservationsLoading] = useState(true);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [transactionLoading, setTransactionLoading] = useState(false);
	const [openAppRedirectModal, setOpenAppRedirectModal] = useState(false);
	const [AppRedirectMessage, setAppRedirectMessage] = useState("");
	const [myProfessionalId, setMyProfessionalId] = useState(null);
	const [openFreeTest, setOpenFreeTest] = useState(false);
	const [reaminingFreeTestDays, setReaminingFreeTestDays] = useState(0);
	const [openNewSchedule, setOpenNewSchedule] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", (e) => {
			setUpdate(!update);
		});
	}, []);

	const getMyProfessionalId = (me, professionals, business) => {
		professionals.forEach((professional) => {
			if (
				me.id !== business.ownerId &&
				professional.phone === me.phone &&
				(professional.permission === "owner" || professional.permission === "manager")
			) {
				setMyProfessionalId(professional.id);
			}
		});
	};

	const fetchReservations = async (businessId, date, setReservations, setLoading) => {
		try {
			const formattedDate = moment(date).format("YYYY-MM-DD");
			const nextDay = moment(date).add(1, "days").format("YYYY-MM-DD");
			const response = await getDatedBookings(businessId, formattedDate, nextDay);
			setReservations(response.data.bookings);
			setScheduleBreak(response.data.scheduleBreaks);
		} catch (error) {
			setAppRedirectMessage(
				"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
			);
			setOpenAppRedirectModal(true);
			setReservationsLoading(false);
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (businessData) {
			dispatch(businessAction.getBusiness());
			dispatch(clientAction.getClients(businessData?.id, myProfessionalId));
			dispatch(sectorAction.getSector(businessData?.id));
			api
				.get(`subscription?business_id=${businessData.id}`)
				.then((res) => {
					let finalFreeTestData = new Date(businessData.createdAt);
					finalFreeTestData.setDate(finalFreeTestData.getDate() + 7);
					if (finalFreeTestData.getTime() > Date.now()) {
						setReaminingFreeTestDays(Math.floor((finalFreeTestData.getTime() - Date.now()) / 86400000));
						setOpenFreeTest(true);
					}
					if (finalFreeTestData.getTime() <= Date.now() && new Date(res.data[0].expiration).getTime() <= Date.now()) {
						//navigate("/expired");
					}
				})
				.catch((err) => {});
			api
				.get("feed?page=1&per_page=20")
				.then((res) => {
					setNotifications(res.data);
				})
				.catch((err) => {});
		}
	}, [businessData]);

	useEffect(() => {
		let arr = [];
		for (var i = 0; i < 24; i++) {
			arr.push(i);
		}
		setHours(arr);
	}, []);

	setTimeout(() => {
		setInterval(() => {
			setNow(new Date(Date.now()));
		}, 60000);
	}, now.getSeconds() * 1000);

	useEffect(() => {
		let lastCheck = localStorage.getItem("@prit-web/lastReminderCheck");
		if (lastCheck !== null) {
			setLastReminderCheckDate(new Date(lastCheck));
			let count = 0;
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, []);

	useEffect(() => {
		let count = 0;
		if (notifications) {
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [notifications, lastReminderCheckDate]);

	const handleCheckAllReminders = () => {
		localStorage.setItem("@prit-web/lastReminderCheck", Date.now());
		setLastReminderCheckDate(Date.now());
		setNewsCount(0);
	};

	useEffect(() => {
		const request = setInterval(() => {
			setLoading(true);
			if (auth?.currentUser) {
				api
					.get(
						"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=true"
					)
					.then((res) => {
						
						if (!res.data.business) {
							setLoading(false);
							setBusinessData(null);
							setAppRedirectMessage(
								"Parece que você ainda não possui um estabelecimento... Baixe agora o Prit para Profissionais e crie um novo estabelecimento para você!"
							);
							setOpenAppRedirectModal(true);
						} else {
							setBusinessData(res.data.business);
							setProfessionals(res.data.professionals);
							if(!priceTypes) {
								dispatch(fetchPriceTypesSuccess(res.data.pricingTypes, {type:'FETCH_PRICE_TYPES_SUCCESS'}));
							} 
							setScheduleBreakDates(res.data.scheduleBreaksDates);
						}
						setReservationDates(res.data.bookingDates);
						setReservationsLoading(false);
						setReservations(res.data.bookings);
						setLoading(false);
						getMyProfessionalId(res.data.me, res.data.professionals, res.data.business);
						clearInterval(request);
					})
					.catch((err) => {
						setAppRedirectMessage(
							"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando com seus clientes!"
						);
						setOpenAppRedirectModal(true);
						setLoading(false);
						setReservationsLoading(false);
					});
			} else {
				setAuth(getAuth());
				if (auth.currentUser === null) {
					navigate("/login");
				}
			}
		}, 1000);

		return () => clearInterval(request);
	}, []);

	useEffect(() => {
		setReservationsLoading(true);
	}, [date]);

	useEffect(() => {
		if (businessData?.id) {
			fetchReservations(businessData.id, date, setReservations, setLoading);
		}
	}, [date]);

	useEffect(() => {
		if (selectedBooking?.bookingTransactionId) {
			api
				.get("booking_transaction/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedBookingTransaction(res.data);
					setTransactionLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setTransactionLoading(false);
				});
		}
		if (selectedBooking?.bookingTransactionId) {
			api
				.get("payment/list/grouped/payments/" + selectedBooking?.bookingTransactionId)
				.then((res) => {
					setSelectedPaymentList(res.data);
					setPaymentLoading(false);
				})
				.catch((err) => {
					setAppRedirectMessage(
						"Parece que estamos com problemas técnicos... Baixe agora o Prit para Profissionais continue agendando comseus clientes!"
					);
					setOpenAppRedirectModal(true);
					setPaymentLoading(false);
				});
		}
	}, [selectedBooking, transactionLoading, paymentLoading]);

	useEffect(() => {
		if (selectedBooking && !transactionLoading && !paymentLoading) {
			setOpenDescription(true);
		}
	}, [transactionLoading, paymentLoading]);

	const events = reservations.map((booking) => ({
		id: booking.id,
		title: `${booking?.service?.name} com ${booking?.professional?.name}`,
		start: new Date(booking?.startTime),
		end: new Date(booking?.endTime),
		resource: booking,
		color: booking?.professional?.color,
		...booking
	}));

	const scheduleBreakList = scheduleBreak.map((breakItem) => {
		const foundProfessional = professionals.find((p) => p.id === breakItem.professionalId);
			return {
				id: breakItem.id,
				professionalName: foundProfessional ? foundProfessional.name : '',
				title: foundProfessional
					? `Horário Bloqueado - ${foundProfessional.name}`
					: 'Horário Bloqueado',
				start: new Date(breakItem.startTime),
				end: new Date(breakItem.endTime),
			};
	});

	return (
		<div className={classes.calendarContainer}>
			<Sidebar />

			<Divider orientation="vertical" flexItem className="divider" />

			<div className={classes.reservationList}>
				{pageType === "root" ? (
					<>
						<div
							className="date-header"
							style={{ width: "95%", alignItems: "center", justifyContent: "space-between" }}>
							{professionals?.length > 0 && (
								<div style={{ display: "flex", marginLeft: "30px" }}>
									<ProfessionalSelect name="Todos" onClick={() => setFiltredProfessional(null)} />
									<div style={{ display: "flex", padding: "15px" }}>
										{professionals.map((professional) => (
											<ProfessionalSelect
												key={professional.id}
												name={professional.name}
												onClick={() => setFiltredProfessional(professional)}
												selected={filtredProfessional?.id === professional?.id}
											/>
										))}
									</div>
								</div>
							)}
							<div>
								<button className="newBooking" onClick={() => setOpenNewSchedule(true)}>
									Adicionar um evento +
								</button>
							</div>
						</div>
						<Divider />
						<div>
							<CalendarTimeline events={events} breaks={scheduleBreakList} date={date} reservations={reservations} onNavigate={(newDate) => setDate(newDate)}/>
						</div>

						<button className="floating-button" onClick={() => setOpenNewSchedule(true)}>
							<AddIcon style={{ fontSize: "30px" }} />
						</button>
					</>
				) : (
					<SchedulePages type={pageType} />
				)}
			</div>
			<Dialog
				open={openNewSchedule}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<NewScheduleDialog date={date} onClose={() => setOpenNewSchedule(false)}></NewScheduleDialog>
			</Dialog>
		</div>
	);
};

export default Schedule;
