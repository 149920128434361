import React, { useEffect, useRef, useState } from "react";
import { Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { businessProfileSchema, initialBusinessForm } from "./businessProfile-schema";
import { Edit, Photo, Trash } from "../common/icons/Icons";
import Dialog from "../dialog";
import businessService from "../../services/businessService";
import Constants from "../../helpers/Constants";
import { randomInt } from "../../utils";

import "./style.css";
import imageProfileDefault from "../../assets/icons/profile.png";

export default function BusinessProfile() {
	const [loading, setLoading] = useState(false);
	const [photos, setPhotos] = useState([]);
	const [deletePhoto, setDeletePhoto] = useState(null);

	const refUploadCoverProfile = useRef(null);
	const refUploadLogoProfile = useRef(null);
	const refUploadPhotosProfile = useRef(null);
	const { business } = useSelector((store) => store.business);
	const dispatch = useDispatch();

	const { handleSubmit, register, setValue, watch } = useForm({
		resolver: yupResolver(businessProfileSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialBusinessForm,
	});

	const loadData = async () => {
		const listPhotos = await businessService.getBusiness();
		const { name, description, phone, social, address, photos } = listPhotos.data[0];
		setPhotos(photos);

		setValue("name", name);
		setValue("description", description);
		setValue("cellphone", phone);
		setValue("socialInstagram", social.instagram);
		setValue("socialDesktop", social.desktop);
		setValue("addressPostalCode", address.postalCode);
		setValue("addressStreet", address.street);
		setValue("addressNumber", address.number);
		setValue("addressComplement", address.unit);
		setValue("addressReferencePoint", address.referencePoint);
		setValue("addressNeighborhood", address.neighborhood);
		setValue("addressCity", address.city);
		setValue("addressState", address.state);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		const businessData = {
			name: data.name,
			description: data.description,
			phone: data.cellphone,
			social_instagram: data.socialInstagram,
			website: data.socialDesktop,
			address_postal_code: data.addressPostalCode,
			address_street: data.addressStreet,
			address_number: data.addressNumber,
			address_unit: data.addressComplement,
			address_reference_point: data.addressReferencePoint,
			address_neighborhood: data.addressNeighborhood,
			address_city: data.addressCity,
			address_state: data.addressState,
		};

		const response = await businessService.putBusiness(business.id, businessData);
		if (response.status === 200) {
			dispatch({
				type: Constants.GET_BUSINESS,
				payload: {
					business: {
						...business,
						...businessData,
					},
				},
			});
		}
		setLoading(false);
	};

	const handleClickUploadCover = () => {
		refUploadCoverProfile.current.click();
	};

	const handleClickPhotosProfile = () => {
		refUploadPhotosProfile.current.click();
	};

	const handleClickLogoProfile = () => {
		refUploadLogoProfile.current.click();
	};

	const handleSubmitImage = async (event) => {
		const field = event.target.name;
		const file = event.target.files[0];
		const formData = new FormData();
		const fileName = randomInt(1000000000).toString() + "_" + file.name;
		formData.append("image", file, fileName);
		try {
			const response = await businessService.uploadImage(formData);
			if (response.status === 200) {
				if (field === "logo") {
					handleUpdateLogo({
						thumbnailUrl: "https://storage.googleapis.com/prit-images/" + fileName,
					});
				} else {
					handleSendPhotos(
						{
							business_id: business.id,
							url: "https://storage.googleapis.com/prit-images/" + fileName,
						},
						field
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateLogo = async (data) => {
		const response = await businessService.putBusiness(business.id, data);
		if (response.status === 200) {
			dispatch({
				type: Constants.GET_BUSINESS,
				payload: {
					business: {
						...business,
						thumbnailUrl: data.thumbnailUrl,
					},
				},
			});
		}
	};

	const handleSendPhotos = async (data, updateCover) => {
		const response = await businessService.sendPhotos(data);
		if (response.status === 200) {
			if (updateCover === "cover" && photos.length) {
				let order = 1;
				const new_photos = [
					{
						id: response.data.id,
						order,
					},
				];
				photos.forEach((element) => {
					order++;
					new_photos.push({ id: element.id, order });
				});
				await businessService.updatePhotos({ photos: new_photos, business_id: business.id }, business.id);
			}
			const watchAllFields = watch();
			await onSubmit(watchAllFields);
			await loadData();
		}
	};

	const handleSelectPhotoToDelete = (photoId) => {
		setDeletePhoto(photoId);
	};

	const handleDeletePhoto = async (photoId) => {
		const response = await businessService.deletePhoto(photoId);
		if (response.status === 204) {
			const newPhotos = photos.filter((photo) => photo.id !== photoId);
			setPhotos(newPhotos);
			setDeletePhoto(null);
		}
	};

	useEffect(() => {
		if (business.id) {
			loadData();
		}
	}, [business]);

	return (
		<>
			<div className="date-header">
				<Typography className="date-header-title">Perfil da Empresa</Typography>
			</div>
			<Divider />
			<div className="form business-profile">
				<StyledCover className="form-cover" background={photos?.length ? photos[0].url : ""}>
					<div>
						<input type="file" name="cover" ref={refUploadCoverProfile} hidden={true} onChange={handleSubmitImage} />
						<button onClick={handleClickUploadCover} className="form-cover-button-upload">
							<Edit />
						</button>
					</div>
				</StyledCover>
				<div className="form-logo">
					<StyledLogo className="logo" background={business.thumbnailUrl}>
						<>
							<input type="file" name="logo" ref={refUploadLogoProfile} hidden={true} onChange={handleSubmitImage} />
							<button onClick={handleClickLogoProfile} className="form-logo-button-upload">
								<Photo />
							</button>
						</>
					</StyledLogo>
				</div>
				<div className="form-content">
					<div className="input-form">
						<Typography className="input-form-label">Nome</Typography>
						<input {...register("name")} className="input-form-text" type="text" />
					</div>
					<div className="input-form">
						<Typography className="input-form-label">Descrição</Typography>
						<textarea {...register("description")} className="input-form-description" type="text" />
					</div>
					<div className="input-form">
						<Typography className="input-form-label">Telefone</Typography>
						<input {...register("cellphone")} className="input-form-text" type="text" />
					</div>
					<div className="input-form">
						<Typography className="input-form-label">Instagram</Typography>
						<input {...register("socialInstagram")} className="input-form-text" type="text" />
					</div>
					<div className="input-form">
						<Typography className="input-form-label">Site</Typography>
						<input {...register("socialDesktop")} className="input-form-text" type="text" />
					</div>
					<div className="input-form-upload photos-profile">
						<div className="photos-input-profile">
							<input
								type="file"
								name="photos"
								ref={refUploadPhotosProfile}
								hidden={true}
								onChange={handleSubmitImage}
							/>
							<Typography className="input-form-label">Portfolio</Typography>
							<button onClick={handleClickPhotosProfile} className="button-upload">
								Fazer upload das fotos
							</button>
						</div>
						<div className="list-photos">
							{photos?.length ? (
								photos.map((photo) => {
									return (
										<StyledPhotos key={photo.id} background={photo.url}>
											<button onClick={() => handleSelectPhotoToDelete(photo.id)} className="button-delete-photo">
												<Trash style={{ width: "16px", height: "16px", fill: "white" }} />
											</button>
										</StyledPhotos>
									);
								})
							) : (
								<>Não há fotos em seu Portfolio!</>
							)}
						</div>
					</div>
					<div>
						<Typography className="input-form-label">Endereço</Typography>
						<div className="form-content-address">
							<div className="input-form-address">
								<Typography className="input-form-label">CEP</Typography>
								<input {...register("addressPostalCode")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Logradouro</Typography>
								<input {...register("addressStreet")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Número</Typography>
								<input {...register("addressNumber")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Complemento</Typography>
								<input {...register("addressComplement")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Ponto de Referência</Typography>
								<input {...register("addressReferencePoint")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Bairro</Typography>
								<input {...register("addressNeighborhood")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Cidade</Typography>
								<input {...register("addressCity")} className="input-form-text-address" type="text" />
							</div>
							<div className="input-form-address">
								<Typography className="input-form-label">Estado</Typography>
								<input {...register("addressState")} className="input-form-text-address" type="text" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="button-container">
				<button onClick={handleSubmit(onSubmit)} type="button" className="button-save" disabled={loading}>
					Salvar
				</button>
			</div>
			<Dialog
				title="Realmente deseja remover esta foto?"
				isOpen={!!deletePhoto}
				handleFirstBtn={() => handleDeletePhoto(deletePhoto)}
				handleSecondBtn={() => setDeletePhoto(null)}
				lblBtnFirst="Sim"
				colorBtnSecond={"#FF0000"}
			/>
		</>
	);
}

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "initial")};
	background-position: center;
`;

const StyledCover = styled.div`
	${(props) => (props.background ? `background-image: url("${props.background}");` : "")}
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`;

const StyledPhotos = styled.div`
	${(props) => (props.background ? `background-image: url("${props.background}");` : "")}
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`;
