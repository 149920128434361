import * as Yup from "yup";

export const businessProfileSchema = Yup.object({
	name: Yup.string().required("Nome é obrigatório"),
	cellphone: Yup.string().required("Telefone é obrigatório"),
	addressPostalCode: Yup.string().required("CEP é obrigatório"),
	addressStreet: Yup.string().required("Rua é obrigatória"),
	addressNumber: Yup.string().required("Número é obrigatório"),
	addressNeighborhood: Yup.string().required("Bairro é obrigatório"),
	addressCity: Yup.string().required("Cidade é obrigatória"),
	addressState: Yup.string().required("Estado é obrigatório"),
});

export const initialBusinessForm = {
	id: "",
	thumbnailUrl: "",
	name: "",
	description: "",
	phone: "",
	socialInstagram: "",
	socialDesktop: "",
	photos: [],
	addressPostalCode: "",
	addressStreet: "",
	addressNumber: "",
	addressComplement: "",
	addressNeighborhood: "",
	addressCity: "",
	addressState: "",
};
