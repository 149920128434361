import React, { useState } from "react";
import { MenuItem, Select, TextField, Typography, Dialog, Divider, Button } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraPOR from "assets/icons/POR.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraANG from "assets/icons/ANG.png";
import BandeiraFRA from "assets/icons/FRA.png";

import {
	telephoneMask,
	telephoneMaskPOR,
	telephoneMaskEUA,
	telephoneMaskING,
	telephoneMaskANG,
	telephoneMaskFRA,
	trimTelephoneNumber,
	getDdiNumber,
} from "utils";

import { initialNewClientForm, newClientSchema } from "../listclients-schema";
import { postClient } from "../../../services/clientService";
import "./style.css";
import { isMobile } from "utils/isMobile";
import { Close } from "@material-ui/icons";

export default function ClientForm({ handleUpdate, closeForm }) {
	const [loading, setLoading] = useState(false);
	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [phoneDdi, setPhoneDdi] = useState(1);

	const { business } = useSelector((store) => store.business);

	const { handleSubmit, register, setValue, watch } = useForm({
		resolver: yupResolver(newClientSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: initialNewClientForm,
	});

	const handleChangeType = (e) => {
		setValue("phone", "");
		setPhoneDdi(e.target.value);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		const formatPhone = `+${getDdiNumber(phoneDdi)}${trimTelephoneNumber(data.phone)}`;

		postClient({
			name: data.name,
			phone: formatPhone,
			email: data.email,
			birthday: null,
			addressCity: null,
			addressNeighborhood: null,
			addressNumber: null,
			addressPostalCode: null,
			addressState: null,
			addressStreet: null,
			addressUnit: null,
			businessId: business.id,
			avatarUrl: null,
			observation: null,
			active: true,
		})
			.then(() => {
				handleUpdate();
				setLoading(false);
				alert("Cliente cadastrado com sucesso!");
			})
			.catch((error) => {
				console.log("🚀 ~ onSubmit ~ error", error);
				alert(error.response.data.message);
				setLoading(false);
			});
	};

	const handleChange = (e) => {
		if (phoneDdi === 1) {
			telephoneMask(e);
		} else if (phoneDdi === 2) {
			telephoneMaskPOR(e);
		} else if (phoneDdi === 3) {
			telephoneMaskEUA(e);
		} else if (phoneDdi === 4) {
			telephoneMaskING(e);
		} else if (phoneDdi === 5) {
			telephoneMaskANG(e);
		} else if (phoneDdi === 6) {
			telephoneMaskFRA(e);
		}
		console.log("🚀 ~ handleChange ~ phoneMasked:", e.target.value);
		setValue("phone", e.target.value);
		setTelephoneNumber(e.target.value);
	};

	return (
		<div className="side-client">
			<div className="box-client">
				<form className="form" style={{ width: isMobile() ? "100%" : "60%" }}>
					<div className="input-client">
						<Typography className="input-form-label">Nome</Typography>
						<input {...register("name")} className="input-client-text" type="text" />
					</div>
					<div className="input-client">
						<Typography className="input-form-label">E-mail</Typography>
						<input {...register("email")} className="input-client-text" type="email" />
					</div>
					<div className="input-client">
						<Typography className="input-form-label">Telefone</Typography>
						<div style={{ display: "flex", gap: "16px", width: "100%" }}>
							<Select
								style={{
									paddingLeft: "16px",
									backgroundColor: "#D9D9D9",
									color: "#3E3E3E",
									borderRadius: "8px",
									borderBottom: "none",
								}}
								value={phoneDdi}
								label={phoneDdi}
								onChange={handleChangeType}>
								<MenuItem value={1}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraBRA} alt="BandeiraBRA" /> +55
								</MenuItem>
								<MenuItem value={2}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraPOR} alt="BandeiraPOR" /> +351
								</MenuItem>
								<MenuItem value={3}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraEUA} alt="BandeiraEUA" /> +1
								</MenuItem>
								<MenuItem value={4}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraING} alt="BandeiraING" /> +44
								</MenuItem>
								<MenuItem value={5}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraANG} alt="BandeiraANG" /> +244
								</MenuItem>
								<MenuItem value={6}>
									<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraFRA} alt="BandeiraFRA" /> +33
								</MenuItem>
							</Select>
							<TextField
								id="inputTelephoneNumber"
								className="input-client-text"
								value={telephoneNumber}
								variant="outlined"
								placeholder="Ex: (11) 9 9300-0000"
								onChange={handleChange}
								style={{ flex: 1 }}
								type="tel"
								inputProps={{
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
							/>
						</div>
					</div>
					<div className="button-client-container">
						<button onClick={handleSubmit(onSubmit)} type="submit" className="button-save" disabled={loading}>
							Salvar
						</button>
						<button onClick={closeForm} type="button" className="button-cancel" disabled={loading}>
							Cancelar
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
